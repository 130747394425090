import styled from 'styled-components';
import {
  Select as AntdSelect,
  Row as AntdRow,
  Input as AntdInput,
  Col as AntdCol,
  Button as AntButton,
} from 'antd';

import colors from '../../styles/colors';

export const Select = styled(AntdSelect)`
  width: 99%;
`;

export const Row = styled(AntdRow)`
  margin: 5px;
  padding: 10px;
`;

export const Col = styled(AntdCol)``;

export const Search = styled(AntdInput.Search)`
  width: 99%;
  border-color: ${colors.secundaria};

  input:hover {
    border-color: ${colors.primaria} !important;
    box-shadow: 0 0 0 2px ${colors.primaria} !important;
  }

  input:focus {
    border-color: ${colors.primaria} !important;
    box-shadow: 0 0 0 2px ${colors.primaria} !important;
  }

  button {
    height: 40px !important;
    background-color: ${colors.secundaria};
    border-color: ${colors.secundaria};

    &:hover {
      background-color: ${colors.primaria};
      border-color: ${colors.primaria};
    }

    &:active {
      background-color: ${colors.terciara};
      border-color: ${colors.terciara};
    }
  }
`;

export const Button = styled(AntButton)`
  width: 100%;

  background-color: ${colors.primaria};
  color: ${colors.texto};
  border-color: ${colors.primaria};

  &:hover {
    color: ${colors.primaria};
    border-color: ${colors.primaria};
    background-color: ${colors.secundaria};
  }

  &:focus {
    color: ${colors.secundaria};
    border-color: ${colors.primaria};
    background-color: ${colors.primaria};
  }

  @media only screen and (max-width: 640px) {
    background-color: ${colors.secundaria};
    color: #ffff;
    border-color: ${colors.secundaria};
  }

  &:focus {
    color: ${colors.secundaria};
    border-color: ${colors.secundaria};
    background-color: ${colors.primaria};
  }
`;

import React from 'react';
import { Spin } from 'antd';
import { func } from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { Campo } from '../../layout';
import { useApi } from '../../../hooks';
import { onFinish } from '../functions';
import { Form } from '../styles';
import { Input, Botao, Container } from './styles';

const UsuarioForm = ({ afterSubmit }) => {
  const { criar } = useApi();
  const [form] = Form.useForm();
  const { loading } = useSelector(
    ({ sistemaReducer }) => sistemaReducer,
    shallowEqual,
  );

  const cadastrar = async values => {
    const { data } = await criar(
      '/usuarios',
      values,
      'Usuário criado com sucesso.',
    );
    return data;
  };

  return (
    <Container>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        onFinish={values => {
          onFinish(values, cadastrar, null, afterSubmit, form.resetFields);
        }}
      >
        <Spin spinning={loading}>
          <Campo label="NOME" obrigatorio fluido name="nome">
            <Input type="text" placeholder="Antônio" maxLength={50} />
          </Campo>

          <Campo label="SOBRENOME" name="sobrenome" fluido obrigatorio>
            <Input type="text" placeholder="Silva" maxLength={50} />
          </Campo>

          <Campo label="E-MAIL" name="email" fluido obrigatorio>
            <Input
              type="email"
              placeholder="email@empresa.com.br"
              maxLength={50}
            />
          </Campo>

          <Botao type="primary" htmlType="submit">
            Criar a Minha Conta
          </Botao>
        </Spin>
      </Form>
    </Container>
  );
};

UsuarioForm.propTypes = {
  afterSubmit: func,
};

UsuarioForm.defaultProps = {
  afterSubmit: null,
};

export default UsuarioForm;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { any, bool, func, objectOf } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Drawer, Form } from 'antd';

import { Button, InputFormulario } from '../styles';
import Campo from '../../layout/Campo';

import { useApi } from '../../../hooks/index';
import { P } from './style';

const AdicionarUsuarioForm = ({
  drawerStatus,
  onCloseDrawer,
  afterSubmit,
  user,
}) => {
  const [form] = Form.useForm();
  const { criar, alterar } = useApi();
  const [alterPassword, setAlterPassword] = useState(false);

  useEffect(() => {
    form.setFieldsValue(user);
  }, [form, user]);

  const handleAddUser = async ({ userData }) => {
    const { nome } = userData;

    const { data } = await criar(
      '/usuarios',
      userData,
      `Usuário ${nome} adicionado  com sucesso.`,
    );

    return data;
  };

  const handleAlterUser = async ({ userData }) => {
    const { data } = await alterar(
      `/usuarios/${user.uid}`,
      userData,
      'Usuário Alterado',
    );

    return data;
  };

  const onShowOrHidePasswordInputs = async () => {
    if (!alterPassword) {
      setAlterPassword(true);
    } else {
      setAlterPassword(false);
    }
  };

  return (
    <>
      <Drawer
        placement="right"
        visible={drawerStatus}
        onClose={() => onCloseDrawer()}
        title={user ? 'Alterar Usuario' : 'Cadastrar novo usuário'}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={async value => {
            if (user) {
              await handleAlterUser({ userData: value });
              onCloseDrawer();
              afterSubmit();
            } else {
              await handleAddUser({ userData: value });
              onCloseDrawer();
              afterSubmit();
            }
          }}
        >
          <Campo name="nome" label="Nome" obrigatorio>
            <InputFormulario placeholder="Nome" />
          </Campo>
          <Campo name="sobrenome" label="Sobrenome" obrigatorio>
            <InputFormulario placeholder="Sobrenome" />
          </Campo>
          <Campo name="email" label="E-mail" obrigatorio>
            <InputFormulario placeholder="E-mail" />
          </Campo>

          <P onClick={onShowOrHidePasswordInputs}>
            {alterPassword ? 'Ocultar senha' : 'Alterar senha'}
          </P>

          {alterPassword && (
            <>
              <Campo name="novaSenha" label="Senha" obrigatorio>
                <InputFormulario placeholder="***********" type="password" />
              </Campo>

              <Campo
                name="confirmacaoSenha"
                label="Confirmar Senha"
                obrigatorio
              >
                <InputFormulario placeholder="***********" type="password" />
              </Campo>
            </>
          )}
          <Button style={{ width: 'auto' }} htmlType="submit" type="primary">
            Salvar
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

AdicionarUsuarioForm.propTypes = {
  drawerStatus: bool,
  onCloseDrawer: func,
  afterSubmit: func,
  user: objectOf(any),
};

AdicionarUsuarioForm.defaultProps = {
  drawerStatus: false,
  onCloseDrawer: null,
  afterSubmit: null,
  user: null,
};

export default AdicionarUsuarioForm;

const config = [
  {
    titulo: 'Papel',
    aninhamento: 'papel',
    atributo: 'nome',
    variavel: true,
  },
];

export default {
  titulo: 'Papéis do Usuário',
  cabecalho: {
    desktop: config,
    mobile: config,
  },
};

import {
  MinhaConta,
  DetalhesContrato,
  MeusContratos,
  MeusAcessos,
  Contratar,
  DetalhesCnpj,
  Indicar,
  Convidar,
  GerenciarUsuarios,
  DetalhesUsuario,
  Papeis,
} from '../pages';

export default [
  { path: '/minha-conta', component: MinhaConta },
  { path: '/indicar', component: Indicar },
  { path: '/convidar', component: Convidar, exact: true },
  { path: '/contratar', component: Contratar, exact: true },
  { path: '/meus-acessos', component: MeusAcessos, exact: true },
  { path: '/meus-contratos', component: MeusContratos, exact: true },
  { path: '/meus-contratos/:id', component: DetalhesContrato, exact: true },
  { path: '/gerenciar-usuario', component: GerenciarUsuarios, exact: true },
  { path: '/gerenciar-usuario/:id', component: DetalhesUsuario, exact: true },
  {
    path: '/meus-contratos/cnpj/:id',
    component: DetalhesCnpj,
    exact: true,
  },
  {
    path: '/papeis',
    component: Papeis,
    exact: true,
  },
];

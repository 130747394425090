export const lerObjectProp = (objeto = {}, setarCampo, limparCampos) => {
  const chaves = Object.keys(objeto);

  if (chaves.length === 0) {
    return limparCampos?.();
  }
  return chaves.forEach(key => {
    setarCampo({ [key]: objeto[key] });
  });
};

export const onFinish = async (
  values,
  cadastrarFunction,
  editarFunction,
  afterSubmit,
  limparCampos,
) => {
  const { objectUid: uid } = values;
  delete values.objectUid;
  let newValues = null;
  if (uid) {
    newValues = await editarFunction(values, uid);
  } else {
    newValues = await cadastrarFunction(values);
  }
  if (afterSubmit) afterSubmit(newValues);
  if (limparCampos && newValues) limparCampos();
};

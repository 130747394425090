import styled from 'styled-components';
import { Button } from 'antd';

export const ItemContainer = styled.div``;

export const AlterarButton = styled(Button)`
  border: none;
  outline: none;
  color: #1890ff;
  background: transparent;
  box-shadow: none;
`;

export const Item = styled.div`
  overflow-wrap: break-word;
  margin-top: ${({ margem }) => !margem && `7px`};
  padding: 2px;
  display: ${({ inline }) => (inline ? 'flex' : 'block')};
  h4 {
    display: block;
    margin-bottom: 0px;
    font-weight: 600;
  }
`;

export const ItemContent = styled.div`
  ${({ icone }) => (icone ? `margin-right: 10px` : `width: 100%`)};
`;

export const DescritorContainer = styled.div`
  height: auto;
  overflow: auto;
  border-radius: 2px;
  width: ${inline => (inline ? '100%' : 'auto')};

  ${ItemContainer} {
    max-width: ${({ inline }) => !inline && '250px'};
    width: ${inline => (inline ? '100%' : 'auto')};
  }

  ${ItemContent} {
    ${({ inline }) =>
      inline &&
      `
       display: flex;
       justify-content: space-between;
  `}
  }

  ${Item}:not(:last-child) {
    border-bottom: ${({ inline }) => (inline ? '1px solid #f0f0f0' : 'none')};
  }

  ${Item} {
    width: inherit;
    justify-content: space-between;
    h4 {
      margin-left: ${({ inline }) => (inline === 'true' ? '10px' : '0px')};
    }
  }
`;

export const SectionContainer = styled.div``;

export const PanelContainer = styled.div`
  display: flex;
  justify-content: ${props =>
    props.quantidadeColunas > 2 ? 'space-between' : 'normal'};
  ${SectionContainer} {
    margin-right: 30px;
  }
  @media only screen and (max-device-width: 640px) {
    display: block;
  }
`;

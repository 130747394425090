import React, { useState } from 'react';

import { Drawer, Form } from 'antd';
import { func, bool } from 'prop-types';
import { Campo } from '../../layout';
import { Button, InputFormulario, Switch } from '../styles';
import { useApi } from '../../../hooks';

const PapelForm = ({ drawerStatus, onCloseDrawer, afterSubmit }) => {
  const { criar } = useApi();

  const [statusSwitch, setStatusSwitch] = useState(true);

  const [form] = Form.useForm();

  const handleAddRole = async value => {
    const { nome } = value;

    const { data } = await criar(
      '/papeis',
      { nome, ativo: statusSwitch },
      `Papel ${nome} adicionado com sucesso.`,
    );

    return data;
  };

  const handleChangeSwitchValue = () => {
    if (statusSwitch) {
      setStatusSwitch(false);
    } else {
      setStatusSwitch(true);
    }
  };

  return (
    <>
      <Drawer
        title="Cadastrar papel"
        visible={drawerStatus}
        onClose={() => onCloseDrawer()}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={async value => {
            const resultSubmit = await handleAddRole(value);
            afterSubmit(resultSubmit);
          }}
        >
          <Campo label="Nome" name="nome" obrigatorio fluido>
            <InputFormulario placeholder="Gerente, usuário" />
          </Campo>

          <Campo label="Ativo" name="ativo">
            <Switch
              defaultChecked
              checked={statusSwitch}
              onChange={() => handleChangeSwitchValue(statusSwitch)}
            />
          </Campo>

          <Button htmlType="submit" type="primary">
            Salvar
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

PapelForm.propTypes = {
  drawerStatus: bool,
  onCloseDrawer: func,
  afterSubmit: func,
};

PapelForm.defaultProps = {
  drawerStatus: false,
  onCloseDrawer: null,
  afterSubmit: null,
};

export default PapelForm;

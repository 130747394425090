import React from 'react';
import { objectOf, any } from 'prop-types';
import { UsuarioForm } from '../../components/forms';
import { BlocoDireito, BlocoEsquerdo, Fundo, H2, Imagem } from '../styles';
import logo from '../../assets/logo/horizontal-s-tag2.png';

import { Desktop } from '../../utils/tamanhoDeTela';
import { Icone } from '../../components/layout';

const Cadastrar = ({ history }) => {
  return (
    // <ImageContainer titulo="Criar uma Nova Conta">
    //   <UsuarioForm
    //     afterSubmit={data => {
    //       if (data) {
    //         history.push('/');
    //       }
    //     }}
    //   />
    // </ImageContainer>
    <>
      {Desktop() ? (
        <>
          <Fundo>
            <BlocoEsquerdo>
              <Imagem src={logo} />
            </BlocoEsquerdo>
            <BlocoDireito>
              <Icone history={history} label="Voltar" />
              <H2>Criar uma Nova Conta</H2>
              <UsuarioForm
                afterSubmit={data => {
                  if (data) {
                    history.push('/');
                  }
                }}
              />
            </BlocoDireito>
          </Fundo>
        </>
      ) : (
        <>
          <Fundo style={{ height: 'auto' }}>
            <Imagem src={logo} />
            <H2>Criar uma Nova Conta</H2>
            <UsuarioForm
              afterSubmit={data => {
                if (data) {
                  history.push('/');
                }
              }}
            />
          </Fundo>
        </>
      )}
    </>
  );
};

Cadastrar.propTypes = {
  history: objectOf(any).isRequired,
};

export default Cadastrar;

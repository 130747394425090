export default {
  titulo: 'CNPJs',
  cabecalho: {
    desktop: [
      {
        titulo: 'CNPJ',
        atributo: 'cnpj',
      },
      {
        titulo: 'Razão Social',
        atributo: 'razaoSocial',
        variavel: true,
      },
      {
        titulo: 'Nome Fantasia',
        atributo: 'nomeFantasia',
      },
      {
        titulo: 'Município',
        atributo: 'municipio',
      },
    ],
    mobile: [
      {
        titulo: 'CNPJ',
        atributo: 'cnpj',
      },
    ],
  },
};

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { api } from '../../services/api';
import {
  setToken,
  setLoading,
  setTokenValido,
} from '../../store/modules/sistema/actions';

const useApi = () => {
  const dispatch = useDispatch();

  const processar = useCallback(
    async (funcao, mensagem, callback) => {
      try {
        dispatch(setLoading(true));
        const response = await funcao();
        if (mensagem) message.success(mensagem, 10);
        if (callback) callback();
        return response;
      } catch (error) {
        const { mensagem: errorMessage } = error.response?.data || {
          mensagem:
            'O sistema está indisponível no momento. Tente novamente daqui a pouco!',
        };
        if (errorMessage === 'Não autorizado') {
          dispatch(setTokenValido(false));
          dispatch(setToken(null));
          localStorage.setItem('token', null);
        }
        if (
          error.message === 'Network Error' &&
          localStorage.getItem('erroConnexao') !== 'true'
        ) {
          localStorage.setItem('erroConnexao', 'true');
          message.error(errorMessage, 7, () =>
            localStorage.setItem('erroConnexao', 'false'),
          );
        } else if (error.message !== 'Network Error') {
          message.error(errorMessage, 7);
        }
        return { data: null };
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch],
  );

  return {
    deletar: useCallback(
      (url, mensagem, callback) => {
        return processar(() => api.delete(url), mensagem, callback);
      },
      [processar],
    ),

    buscar: useCallback(
      (url, mensagem) => {
        return processar(() => api.get(url), mensagem);
      },
      [processar],
    ),

    criar: useCallback(
      (url, corpo, mensagem) => {
        return processar(() => api.post(url, corpo), mensagem);
      },
      [processar],
    ),

    alterar: useCallback(
      (url, corpo, mensagem) => {
        return processar(() => api.patch(url, corpo), mensagem);
      },
      [processar],
    ),
  };
};

export default useApi;

/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { Popconfirm, Empty, Switch, Tag } from 'antd';
import {
  DeleteOutlined,
  SyncOutlined,
  StopOutlined,
  ExceptionOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import {
  string,
  object,
  arrayOf,
  bool,
  func,
  objectOf,
  any,
  oneOfType,
} from 'prop-types';
import { isArray } from 'lodash';
import { formatar } from '../../../utils';
import { getTabela } from './functions';
import { ESTORNAR, EXCLUIR, FALTAR, IMPRIMIR, PARAR } from './nomesAcoes';
import { Icone } from '../../../styles/global';
import {
  ButtonContainer,
  Button,
  Cell,
  Table,
  AtributoAgrupado,
  CellContainer,
  ArrayContainer,
} from './styles';

const Tabela = ({
  dados,
  cabecalho,
  titulo,
  loading,
  acoes,
  celulaSelecionada,
  colunas,
  compacta,
  expandir,
  celulaOnChange,
  paginacao,
  ...props
}) => {
  const [columns, setColumns] = useState([]);
  const [tituloTabela, setTituloTabela] = useState(null);

  useEffect(() => {
    const gerarComponente = (componente, objeto, valor) => {
      const componentes = {
        Switch: (
          <Switch
            checked={valor}
            onChange={value => celulaOnChange[componente]?.(value, objeto)}
          />
        ),
      };
      return componentes[componente];
    };

    const renderTag = (record, tags) => {
      if (isArray(tags)) {
        return tags.map((tag, index) => (
          <Fragment key={index}>{renderTag(record, tag)}</Fragment>
        ));
      }
      const {
        atributo,
        subAninhamento,
        aninhamento,
        tipo,
        enumerador,
        color,
        naoMostrarSe,
        texto,
      } = tags;
      let textoFormatado = record;
      if (texto) {
        textoFormatado = typeof texto === 'function' ? texto(record) : texto;
      } else {
        if (aninhamento) {
          textoFormatado = textoFormatado?.[aninhamento];
        }
        if (subAninhamento) {
          textoFormatado = textoFormatado?.[subAninhamento];
        }
        if (tipo) {
          textoFormatado = formatar(
            tipo,
            textoFormatado?.[atributo],
            enumerador,
          );
        } else {
          textoFormatado = textoFormatado?.[atributo];
        }
      }
      return (
        !naoMostrarSe?.(record) &&
        textoFormatado && (
          <Tag color={typeof color === 'function' ? color?.(record) : color}>
            {textoFormatado}
          </Tag>
        )
      );
    };

    const { titulo: nomeTabela, cabecalho: cabecalhoConfig } = cabecalho
      ? getTabela(cabecalho)
      : colunas;
    const cabecalhoTabela =
      window.screen.width >= 640
        ? cabecalhoConfig.desktop
        : cabecalhoConfig.mobile;

    setTituloTabela(nomeTabela);

    const renderTexto = texto => {
      if (texto === true) {
        return 'Sim';
      }
      if (texto === false) {
        return 'Não';
      }
      return texto;
    };

    const gerarIcone = (record, { alt, atributo }, key) => {
      const imagem40 = record?.[atributo];
      let textoAlt;
      alt.forEach(atr => {
        const texto = record?.[atr];
        if (texto && !textoAlt) {
          const [p1, p2] = texto.split(' ');
          if (p2) {
            textoAlt = `${p1[0]}${p2[0]}`;
          } else {
            textoAlt = `${p1[0]}${p1[1] ?? ''}`;
          }
        }
      });
      return (
        textoAlt && (
          <Icone imagem={imagem40} key={key} absoluto="true">
            {!imagem40 && textoAlt.toUpperCase()}
          </Icone>
        )
      );
    };

    const gerarAgrupamentos = (agrupar, record) => {
      return (
        <>
          {agrupar.map(({ atributo: atr, icone, tag: tagAgrupada }, key) => {
            return icone ? (
              gerarIcone(record, icone, key)
            ) : (
              <Fragment key={key}>
                {record?.[atr] && (
                  <AtributoAgrupado>
                    {record?.[atr]}{' '}
                    {tagAgrupada && renderTag(record, tagAgrupada)}
                  </AtributoAgrupado>
                )}
              </Fragment>
            );
          })}
        </>
      );
    };

    const cabecalhos = cabecalhoTabela.map(
      (
        {
          agrupar,
          aninhamento,
          atributo,
          subAninhamento,
          titulo: title,
          tipo,
          uid,
          enumerador,
          onCell,
          variavel,
          width,
          componente,
          valorMonetario,
          align,
          tag,
          custom,
          fundo,
          cor,
          array,
        },
        index,
      ) => {
        let dataIndex = aninhamento ? [aninhamento, atributo] : atributo;
        if (subAninhamento) {
          dataIndex = [aninhamento, subAninhamento, atributo];
        }
        if (agrupar) {
          dataIndex = aninhamento ? [aninhamento, index] : index;
        }
        return {
          title,
          dataIndex,
          onCell,
          align: variavel ? 'left' : align || 'left',
          width: !variavel && !width ? 'min-content' : width,
          render: (text, record) => {
            let texto;
            if (custom) {
              texto = custom(
                record,
                valor => tipo && valor && formatar(tipo, valor, enumerador),
              );
            } else {
              texto = tipo && text ? formatar(tipo, text, enumerador) : text;
            }
            return {
              props: { colSpan: 1 },
              children: typeof (text !== 'object' || array) && (
                <CellContainer
                  key={uid || index}
                  agrupar={agrupar}
                  componente={componente}
                  background={
                    typeof fundo === 'function' ? fundo(record) : fundo
                  }
                >
                  {componente ? (
                    gerarComponente(componente, record, texto)
                  ) : (
                    <Cell
                      cor={cor}
                      align={variavel ? 'left' : align || 'left'}
                      clickable={celulaSelecionada}
                      valorMonetario={valorMonetario}
                      texto={renderTexto(texto)}
                      onClick={() => celulaSelecionada?.(record, text, title)}
                    >
                      {agrupar ? (
                        gerarAgrupamentos(
                          agrupar,
                          aninhamento ? record[aninhamento] : record,
                        )
                      ) : (
                        <>
                          {array ? (
                            <>
                              {record[aninhamento].map((item, key) => (
                                <ArrayContainer key={key}>
                                  {array.map((propriedades, i) => (
                                    <span key={i}>
                                      {propriedades.custom
                                        ? propriedades.custom(
                                            item[propriedades.atributo],
                                            valor =>
                                              propriedades.tipo &&
                                              valor &&
                                              formatar(
                                                propriedades.tipo,
                                                valor,
                                                propriedades.enumerador,
                                              ),
                                          )
                                        : item[propriedades.atributo]}
                                    </span>
                                  ))}
                                </ArrayContainer>
                              ))}
                            </>
                          ) : (
                            <>
                              {renderTexto(texto)}{' '}
                              {tag && renderTag(record, tag)}
                            </>
                          )}
                        </>
                      )}
                    </Cell>
                  )}
                </CellContainer>
              ),
            };
          },
        };
      },
    );

    const adicionarBotao = (nome, funcao, record, confirmacao, index) => {
      const renderButton = () => (
        <Button
          key={index}
          title={nome}
          onClick={!confirmacao ? () => funcao(record) : null}
        >
          {nome === IMPRIMIR && <PrinterOutlined />}
          {nome === FALTAR && <ExceptionOutlined />}
          {nome === PARAR && <StopOutlined />}
          {nome === ESTORNAR && <SyncOutlined />}
          {nome === EXCLUIR && <DeleteOutlined size="large" />}
        </Button>
      );

      return confirmacao === true ? (
        <Popconfirm
          key={index}
          title="Confirmar ação"
          onConfirm={() => funcao(record)}
          okText="Sim"
          cancelText="Não"
        >
          {renderButton()}
        </Popconfirm>
      ) : (
        renderButton()
      );
    };
    /* eslint-disable react/prop-types */
    if (acoes) {
      cabecalhos.push({
        title: 'Ações',
        align: 'center',
        width: 'fit-content',
        render: (text, record) => (
          <ButtonContainer numerobotoes={acoes?.length} key="acoes">
            {acoes?.map(
              (
                { titulo: tituloBotao, onClick, confirmacao, naoMostrarSe },
                index,
              ) => {
                return (
                  !naoMostrarSe?.(record) &&
                  adicionarBotao(
                    tituloBotao,
                    onClick,
                    record,
                    confirmacao,
                    index,
                  )
                );
              },
            )}
          </ButtonContainer>
        ),
      });
    }
    /* eslint-enable react/prop-types */
    setColumns(cabecalhos);
  }, [acoes, cabecalho, celulaOnChange, celulaSelecionada, colunas]);

  return (
    <>
      <Table
        loading={loading}
        compacta={compacta}
        size="middle"
        columns={columns}
        pagination={paginacao}
        dataSource={dados}
        expandable={{ expandedRowRender: expandir }}
        scroll
        rowKey="uid"
        title={
          titulo
            ? () => {
                if (typeof titulo === 'function') {
                  return titulo();
                }
                return <h4>{tituloTabela}</h4>;
              }
            : null
        }
        locale={{
          filterConfirm: 'Ok',
          filterReset: 'Reset',
          emptyText: () => (
            <Empty
              description="Nada encontrado"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ),
        }}
        {...props}
      />
    </>
  );
};

Tabela.propTypes = {
  acoes: arrayOf(any),
  expandir: func,
  celulaOnChange: objectOf(func),
  colunas: objectOf(any),
  loading: bool,
  dados: arrayOf(object).isRequired,
  cabecalho: string,
  titulo: oneOfType([bool, func]),
  compacta: bool,
  ordenarPor: string,
  ordenacao: arrayOf(string),
  celulaSelecionada: func,
  paginacao: bool,
};

Tabela.defaultProps = {
  acoes: null,
  expandir: null,
  colunas: null,
  celulaOnChange: null,
  cabecalho: null,
  titulo: false,
  loading: false,
  compacta: false,
  ordenarPor: null,
  ordenacao: null,
  celulaSelecionada: null,
  paginacao: false,
};

export default Tabela;

import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BACKEND,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

api.setToken = function setToken(token) {
  this.defaults.headers.common.Authorization = token
    ? `Bearer ${token}`
    : undefined;
};

const apiViacep = axios.create({
  baseURL: process.env.REACT_APP_API_VIACEP,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

const apiReceita = axios.create({
  baseURL: process.env.REACT_APP_API_RECEITA,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

export { api, apiReceita, apiViacep };

import React, { useState, useEffect } from 'react';
import { message, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { func } from 'prop-types';
import { onFinish, lerObjectProp } from '../functions';
import { Campo } from '../../layout';
import { setLoading } from '../../../store/modules/sistema/actions';
import { Form, ImagemUpload, Button, InputFormulario, Upload } from '../styles';
import { useApi } from '../../../hooks';
import {
  setContaAvatar,
  setContaNome,
} from '../../../store/modules/conta/actions';

const ContaUsuarioForm = ({ afterSubmit }) => {
  const formData = new FormData();
  const [form] = Form.useForm();
  const { alterar } = useApi();
  const [senhaObrigatoria, setSenhaObrigatoria] = useState(false);
  const dispatch = useDispatch();
  const { loading } = useSelector(
    ({ sistemaReducer }) => sistemaReducer,
    shallowEqual,
  );
  const { nome, avatarUrl: urlImagem } = useSelector(
    ({ contaReducer }) => contaReducer,
    shallowEqual,
  );

  useEffect(() => {
    lerObjectProp({ nome }, form.setFieldsValue, null);
  }, [form.setFieldsValue, nome]);

  const alterarDados = async values => {
    const { data } = await alterar(
      '/perfil',
      values,
      'Os seus dados foram alterados com sucesso',
    );
    if (data) {
      dispatch(setContaNome(data.nome));
    }
    return data;
  };

  const validarUpload = ({ type, size }) => {
    if (!(type === 'image/jpeg' || type === 'image/png')) {
      message.error('Imagem deve ser no formato PNG ou JPEG!');
      return false;
    }
    if (!(size / 1024 / 1024 < 2)) {
      message.error('Imagem deve ser menor que 2MB!');
      return false;
    }
    return true;
  };

  const lerArquivo = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const onChange = ({ file }) => {
    if (file.status === 'uploading') {
      dispatch(setLoading(true));
    } else if (file.status === 'done') {
      lerArquivo(file.originFileObj, () => {
        dispatch(setLoading(false));
      });
    } else if (file.status === 'error') {
      message.error('Ocorreu um erro ao fazer o upload da foto');
      dispatch(setLoading(false));
    }
  };

  const editarImagem = async ({ file }) => {
    formData.append('avatar', file);
    const { data } = await alterar('/perfil/avatar', formData);
    if (data) {
      const { avatarUrl: avatarNovo } = data;
      dispatch(setContaAvatar(avatarNovo));
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      hideRequiredMark
      onFinish={values => {
        onFinish(values, alterarDados, null, afterSubmit, form.resetFields);
      }}
    >
      <Spin spinning={loading}>
        <Campo label="Nome" name="nome" fluido>
          <InputFormulario type="text" placeholder="Antônio" maxLength={50} />
        </Campo>
        <Campo label="Senha atual" name="senhaAtual" fluido>
          <InputFormulario type="password" placeholder="**********" />
        </Campo>
        <Campo label="Senha nova" name="novaSenha" fluido>
          <InputFormulario
            type="password"
            placeholder="**********"
            onChange={({ target }) =>
              setSenhaObrigatoria(target.value.length > 0)
            }
          />
        </Campo>
        <Campo
          label="Confirme a senha nova"
          name="confirmacaoSenha"
          obrigatorio={senhaObrigatoria}
          fluido
        >
          <InputFormulario type="password" placeholder="**********" />
        </Campo>

        <Campo fluido label="Avatar">
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={validarUpload}
            onChange={onChange}
            customRequest={editarImagem}
          >
            {urlImagem ? (
              <ImagemUpload src={urlImagem} alt="avatar" />
            ) : (
              <>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </>
            )}
          </Upload>
        </Campo>

        <Button htmlType="submit" type="primary">
          Salvar
        </Button>
      </Spin>
    </Form>
  );
};

ContaUsuarioForm.propTypes = {
  afterSubmit: func,
};

ContaUsuarioForm.defaultProps = {
  afterSubmit: null,
};

export default ContaUsuarioForm;

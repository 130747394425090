import React from 'react';
import { bool, string, objectOf, any } from 'prop-types';
import { Item } from '../../forms/styles';

const Campo = ({ fluido, name, label, obrigatorio, children }) => {
  return (
    <Item
      fluido={`${fluido ?? false}`}
      name={name}
      label={label}
      rules={[{ required: obrigatorio, message: 'Campo obrigatório' }]}
    >
      {children}
    </Item>
  );
};

Campo.propTypes = {
  fluido: bool,
  obrigatorio: bool,
  name: string,
  label: string,
  children: objectOf(any),
};

Campo.defaultProps = {
  fluido: false,
  obrigatorio: false,
  name: '',
  label: '',
  children: null,
};

export default Campo;

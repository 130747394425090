export default {
  titulo: '',
  cabecalho: {
    desktop: [
      {
        titulo: 'uid',
        atributo: 'uid',
      },
      {
        titulo: 'nome',
        atributo: 'nome',
      },
    ],
    mobile: [
      {
        titulo: 'uid',
        atributo: 'uid',
      },
      {
        titulo: 'nome',
        atributo: 'nome',
      },
    ],
  },
};

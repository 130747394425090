import {
  TabelaCnpj,
  TabelaUsuario,
  TabelaPapeisUsuario,
  TabelaPapeis,
  TabelaPapel,
} from './cabecalhos';
import { CNPJ, PAPEIS, USUARIO, PAPEISUSUARIO, PAPEL } from './nomesCabecalhos';

export const getTabela = tipo => {
  switch (tipo) {
    case CNPJ:
      return TabelaCnpj;
    case PAPEIS:
      return TabelaPapeis;
    case USUARIO:
      return TabelaUsuario;
    case PAPEISUSUARIO:
      return TabelaPapeisUsuario;
    case PAPEL:
      return TabelaPapel;
    default:
      return {
        titulo: 'Cabeçalho não informado',
      };
  }
};

import React from 'react';
import { useDispatch } from 'react-redux';
import { objectOf, any } from 'prop-types';
import { LoginForm } from '../../components/forms';
import { api } from '../../services/api';
import { useUrl } from '../../hooks';
import { setToken, setTokenValido } from '../../store/modules/sistema/actions';
import {
  setContaEmail,
  setContaAtiva,
  setContaAvatar,
  setContaNome,
  setContaSobrenome,
  setContaUid,
  setSuperUsuario,
} from '../../store/modules/conta/actions';

import { Fundo, BlocoDireito, BlocoEsquerdo, Imagem, H2 } from '../styles';

import logo from '../../assets/logo/horizontal-s-tag2.png';

import { Desktop } from '../../utils/tamanhoDeTela';

const Login = ({ history }) => {
  const dispatch = useDispatch();
  const { pegarValorQuery } = useUrl(history);

  const callback = ({ data, email }) => {
    dispatch(setContaEmail(email));
    if (data?.confirmado === true) {
      const { token } = data;
      const { avatarUrl, nome, sobrenome, uid } = data?.usuario;
      const { superUsuario } = data;
      localStorage.setItem('token', token);
      api.setToken(token);
      dispatch(setContaAtiva(true));
      dispatch(setToken(token));
      dispatch(setTokenValido(true));
      dispatch(setContaAvatar(avatarUrl));
      dispatch(setContaNome(nome));
      dispatch(setContaSobrenome(sobrenome));
      dispatch(setContaUid(uid));
      dispatch(setSuperUsuario(superUsuario));
      return history.push(
        pegarValorQuery('redirect')
          ? `/meus-acessos?redirect=${pegarValorQuery('redirect')}`
          : '/meus-acessos',
      );
    }
    if (data) {
      dispatch(setContaAtiva(false));
      history.push('/reenviar-confirmacao');
    }
    return null;
  };

  return (
    <>
      {Desktop() ? (
        <Fundo>
          <BlocoEsquerdo>
            <Imagem src={logo} />
          </BlocoEsquerdo>
          <BlocoDireito>
            <H2>Acessar minha conta</H2>
            <LoginForm afterSubmit={callback} history={history} />
          </BlocoDireito>
        </Fundo>
      ) : (
        <>
          <Fundo>
            <Imagem src={logo} />
            <H2>Acessar minha conta</H2>
            <LoginForm afterSubmit={callback} history={history} />
          </Fundo>
        </>
      )}
    </>
  );
};

Login.propTypes = {
  history: objectOf(any).isRequired,
};

export default Login;

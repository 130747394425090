import styled from 'styled-components';
import { Button } from 'antd';
import colors from '../../../styles/colors';

export const EnderecoContainer = styled.div`
  display: flex;
  h4 {
    margin-right: 10px;
    width: fit-content;
  }
  img {
    margin-left: 5px;
    width: 15px;
    height: 20px;
  }
  @media only screen and (max-device-width: 640px) {
    display: block;
  }
`;

export const Botao = styled(Button)`
  background-color: ${colors.primaria};
  color: ${colors.texto};
  border-color: ${colors.primaria};

  &:hover {
    color: ${colors.primaria};
    border-color: ${colors.primaria};
    background-color: ${colors.secundaria};
  }

  &:focus {
    color: ${colors.secundaria};
    border-color: ${colors.primaria};
    background-color: ${colors.primaria};
  }

  /* @media only screen and (max-width: 640px) {
    background-color: ${colors.secundaria};
    color: #ffff;
    border-color: ${colors.secundaria};
  }

  &:focus {
    color: ${colors.secundaria};
    border-color: ${colors.secundaria};
    background-color: ${colors.primaria};
  } */
`;

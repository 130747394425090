import styled from 'styled-components';
import colors from '../../../styles/colors';

export const FormContainer = styled.div`
  width: 600px;
  margin: 20px;
  @media only screen and (max-device-width: 640px) {
    width: 100%;
    margin: 10px;
  }
`;

export const TabelaContainer = styled.div`
  margin-top: 20px;
  margin: 20px;
`;

export const ButtonPlus = styled.button`
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  background: transparent;
  border: none;
  box-shadow: none;
  color: ${colors.texto};

  &:hover {
    color: ${colors.primaria};
  }

  &:active {
    color: ${colors.primaria};
  }
`;

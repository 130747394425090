import styled from 'styled-components';
import { Tabs as AntTabs } from 'antd';

export const Tabs = styled(AntTabs)`
  .ant-tabs-nav-wrap {
    background: ${({ transparente }) =>
      transparente !== 'true' ? '#fff' : 'transparent'};
    padding: ${({ tirarmargem }) => (!tirarmargem ? '0 1.5em;' : '0px')};
  }
  ${({ tirarmargem }) => {
    return !tirarmargem
      ? `
    margin-bottom: 16px;
    .ant-tabs-content-holder {
      padding: 0 1.5em;
  }
  `
      : `
    margin-bottom: 0px;
    .ant-tabs-content-holder {
      padding: 0px;
    }
  `;
  }}
`;

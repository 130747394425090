import styled from 'styled-components';
import { Row as AntRow, Col as AntCol, Input, Button as AntButton } from 'antd';
import colors from '../styles/colors';

export const Campo = styled(Input)`
  border-radius: 10px;
  text-align: center;

  margin: 0 !important;
  margin-bottom: 10px !important;
  width: 300px;

  &:hover {
    border-color: ${colors.primaria};
    box-shadow: 0 0 0 2px ${colors.primaria};
  }

  &:focus {
    border-color: ${colors.primaria};
    box-shadow: 0 0 0 2px ${colors.primaria};
  }

  @media only screen and (max-width: 640px) {
    width: 240px;
    &:focus {
      border-color: ${colors.secundaria};
      box-shadow: 0 0 0 2px ${colors.secundaria};
    }
  }
`;

export const Col = styled(AntCol)`
  margin-bottom: 10px;
`;

export const Container = styled.div`
  /* height: 100%; */
  padding: 15px;

  .ant-spin-nested-loading {
    height: inherit;
  }
`;

export const Titulo = styled.h2`
  @media only screen and (max-device-width: 640px) {
    text-align: center;
  }
`;

export const DetalhesContainer = styled.div`
  margin: 1.5em;
`;

export const Row = styled(AntRow)`
  justify-content: center;
  @media only screen and (min-device-width: 640px) {
    ${Col}:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

export const Fundo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  @media only screen and (max-width: 640px) {
    width: 100%;
    height: 100vh;
    display: grid;
    padding: 20px;
    background-color: ${colors.primaria} !important;
  }
`;

export const BlocoEsquerdo = styled.div`
  width: 50%;
  height: 100vh;
  background-color: ${colors.primaria};
  padding: 20px;
  align-items: center;
  align-content: center;
  display: flex;
`;

export const BlocoDireito = styled.div`
  width: 50%;
  height: 100vh;
  background-color: ${colors.secundaria};
  padding: 40px;
  display: grid !important;

  align-items: center;
  align-content: center;
  display: flex;
  .fbEhrq .ant-form-item-label label {
    color: #ffff !important;
    font-size: 20px;
  }
`;

export const Imagem = styled.img`
  width: 100%;
`;

export const H2 = styled.h2`
  color: #ffff !important;
  text-align: center;
  font-size: 25px;

  @media only screen and (max-width: 640px) {
    color: ${colors.secundaria} !important;
  }
`;

export const Button = styled(AntButton)`
  width: 100%;

  background-color: ${colors.primaria};
  color: ${colors.texto};
  border-color: ${colors.primaria};

  &:hover {
    color: ${colors.primaria};
    border-color: ${colors.primaria};
    background-color: ${colors.secundaria};
  }

  &:focus {
    color: ${colors.secundaria};
    border-color: ${colors.primaria};
    background-color: ${colors.primaria};
  }

  @media only screen and (max-width: 640px) {
    background-color: ${colors.secundaria};
    color: #ffff;
    border-color: ${colors.secundaria};
  }

  &:focus {
    color: ${colors.secundaria};
    border-color: ${colors.secundaria};
    background-color: ${colors.primaria};
  }
`;

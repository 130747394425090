import React, { useState, useEffect } from 'react';
import { message, Spin } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { func, objectOf, any } from 'prop-types';
import { onFinish, lerObjectProp } from '../functions';
import { Grupo, Button, Form, InputFormulario } from '../styles';
import { apiReceita, apiViacep } from '../../../services/api';
import { Campo, Sessao } from '../../layout';
import { useApi } from '../../../hooks';
import { setLoading } from '../../../store/modules/sistema/actions';

const regexRetirarCaracteresNaoNumericos = /[.,./.-\s]/g;

const ContratoForm = ({ afterSubmit, contrato }) => {
  const dispatch = useDispatch();
  const { criar, alterar } = useApi();
  const [cnpj, setCnpj] = useState(null);
  const [cep, setCep] = useState(null);
  const [form] = Form.useForm();
  const { loading } = useSelector(
    ({ sistemaReducer }) => sistemaReducer,
    shallowEqual,
  );

  useEffect(() => {
    lerObjectProp(contrato?.cliente, form.setFieldsValue, form.resetFields);
  }, [contrato, form.resetFields, form.setFieldsValue]);

  useEffect(() => {
    const buscarDadosClienteApiExterna = async () => {
      try {
        const cnpjFormatado = cnpj?.replace(
          regexRetirarCaracteresNaoNumericos,
          '',
        );
        if (cnpjFormatado?.length === 14) {
          dispatch(setLoading(true));
          const { data } = await apiReceita.get(`/cnpj/${cnpjFormatado}`);
          const {
            telefone,
            email,
            nome,
            fantasia,
            cep: enderecoCep,
            complemento: enderecoComplemento,
            numero: enderecoNumero,
          } = data;
          form.setFieldsValue({
            telefone,
            email,
            razaoSocial: nome,
            nomeFantasia: fantasia || nome,
            enderecoCep: enderecoCep.replace('.', ''),
            enderecoComplemento,
            enderecoNumero,
          });
          setCep(enderecoCep);
        }
      } catch (error) {
        message.error('Ocorreu um erro ao pesquisar CNPJ');
      } finally {
        dispatch(setLoading(false));
      }
    };
    const timer = setTimeout(() => {
      buscarDadosClienteApiExterna();
    }, 1000);

    return () => clearTimeout(timer);
  }, [cnpj, form, dispatch]);

  useEffect(() => {
    const buscarEnderecoClienteApiExterna = async () => {
      try {
        const cepFormatado = cep?.replace(
          regexRetirarCaracteresNaoNumericos,
          '',
        );
        if (cepFormatado?.length === 8) {
          dispatch(setLoading(true));
          const { data } = await apiViacep.get(`/${cepFormatado}/json/`);
          const { logradouro, bairro, localidade } = data;
          form.setFieldsValue({
            enderecoLogradouro: logradouro,
            enderecoBairro: bairro,
            municipio: localidade,
          });
        }
      } catch (error) {
        message.error('Ocorreu um erro ao pesquisar CEP');
      } finally {
        dispatch(setLoading(false));
      }
    };
    const timer = setTimeout(() => {
      buscarEnderecoClienteApiExterna();
    }, 1000);
    return () => clearTimeout(timer);
  }, [cep, form, dispatch]);

  const cadastrarContrato = async values => {
    const { data } = await criar(
      `/contratar/${contrato?.uid}`,
      values,
      'Contrato criado com sucesso!',
    );
    return data;
  };

  const alterarContrato = async values => {
    const { data } = await alterar(
      `/clientes/${contrato?.cliente?.uid}`,
      values,
      'Cliente atualizado!',
    );
    return data;
  };

  return (
    <Form
      form={form}
      layout="vertical"
      hideRequiredMark
      name="controlled-form"
      onFinish={values => {
        onFinish(
          {
            ...values,
            objectUid: contrato?.cliente?.uid,
            enderecoNumero: Number(values?.enderecoNumero),
          },
          cadastrarContrato,
          alterarContrato,
          afterSubmit,
          form.resetFields,
        );
      }}
    >
      <Spin spinning={loading}>
        <Sessao titulo="Dados de Identificação">
          <Grupo>
            <Campo fluido name="apelido" label="Apelido" obrigatorio>
              <InputFormulario
                type="text"
                placeholder="Digite um apelido"
                maxLength={30}
              />
            </Campo>

            <Campo fluido name="cnpj" label="CNPJ" obrigatorio>
              <InputFormulario
                type="text"
                inputMode="numeric"
                placeholder="Digite um CNPJ"
                onChange={({ target }) => setCnpj(target.value)}
                maxLength={18}
              />
            </Campo>
          </Grupo>
          <Campo fluido name="nomeFantasia" label="Nome Fantasia" obrigatorio>
            <InputFormulario
              type="text"
              placeholder="Digite o nome fantasia"
              maxLength={60}
            />
          </Campo>

          <Campo fluido name="razaoSocial" label="Razão Social" obrigatorio>
            <InputFormulario
              type="text"
              placeholder="Digite a razão social"
              maxLength={60}
            />
          </Campo>
        </Sessao>

        <Sessao titulo="Contatos">
          <Grupo>
            <Campo fluido name="celular" label="Celular">
              <InputFormulario
                type="text"
                inputMode="numeric"
                placeholder="Digite um celular"
                maxLength={50}
              />
            </Campo>

            <Campo fluido name="telefone" label="Telefone" obrigatorio>
              <InputFormulario
                type="text"
                inputMode="numeric"
                placeholder="Digite um telefone"
                maxLength={50}
              />
            </Campo>
          </Grupo>

          <Grupo>
            <Campo fluido name="email" label="Email">
              <InputFormulario
                type="email"
                inputMode="email"
                placeholder="Digite um email"
                maxLength={30}
              />
            </Campo>
          </Grupo>
        </Sessao>

        <Sessao titulo="Endereço">
          <Campo name="enderecoCep" label="CEP" obrigatorio>
            <InputFormulario
              onChange={({ target }) => setCep(target.value)}
              type="text"
              placeholder="Digite o CEP"
              inputMode="numeric"
              maxLength={9}
            />
          </Campo>
          <Campo
            fluido
            name="enderecoLogradouro"
            label="Logradouro"
            obrigatorio
          >
            <InputFormulario
              type="text"
              placeholder="Digite o logradouro"
              maxLength={255}
            />
          </Campo>
          <Grupo>
            <Campo fluido name="enderecoNumero" label="Número">
              <InputFormulario
                type="number"
                placeholder="Digite o número"
                maxLength={10}
              />
            </Campo>
            <Campo fluido name="enderecoComplemento" label="Complemento">
              <InputFormulario
                type="text"
                placeholder="Digite o complemento"
                maxLength={255}
              />
            </Campo>
          </Grupo>

          <Campo fluido name="enderecoBairro" label="Bairro" obrigatorio>
            <InputFormulario
              type="text"
              placeholder="Digite o bairro"
              maxLength={60}
            />
          </Campo>

          <Campo fluido name="municipio" label="Município" obrigatorio>
            <InputFormulario
              type="text"
              placeholder="Digite o município"
              maxLength={60}
            />
          </Campo>
        </Sessao>

        <Button htmlType="submit" type="primary">
          Concluir
        </Button>
      </Spin>
    </Form>
  );
};

ContratoForm.propTypes = {
  afterSubmit: func,
  contrato: objectOf(any),
};

ContratoForm.defaultProps = {
  contrato: null,
  afterSubmit: null,
};

export default ContratoForm;

export default {
  titulo: 'Papeis atribuídos ao usuário',
  cabecalho: {
    desktop: [
      {
        titulo: 'Cargo',
        atributo: 'cargo',
      },
      {
        titulo: 'Empresa',
        atributo: 'cnpj',
      },
    ],
    mobile: [
      {
        titulo: 'Cargo',
        atributo: 'cargo',
      },
      {
        titulo: 'Empresa',
        atributo: 'cnpj',
      },
    ],
  },
};

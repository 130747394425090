import React from 'react';
import { objectOf, any } from 'prop-types';
import { Desktop } from '../../utils/tamanhoDeTela';
import { AceitarConviteForm } from '../../components/forms';
import { BlocoDireito, BlocoEsquerdo, Fundo, Imagem } from './styled';
import logo from '../../assets/logo/horizontal-s-tag2.png';

const AceitarConvite = ({ history }) => {
  return (
    <>
      {/* <ImageContainer titulo="Aceitar o Convite">
        <AceitarConviteForm
        history={history}
        afterSubmit={data => {
          if (data) {
            history.push('/');
          }
        }}
      />
    </ImageContainer> */}
      {Desktop() ? (
        <Fundo>
          <BlocoEsquerdo>
            <Imagem src={logo} alt="Logo Efitex" />
          </BlocoEsquerdo>
          <BlocoDireito>
            <AceitarConviteForm
              history={history}
              afterSubmit={data => {
                if (data) {
                  history.push('/');
                }
              }}
            />
          </BlocoDireito>
        </Fundo>
      ) : (
        <Fundo>
          <Imagem src={logo} alt="Logo Efitex" />
          <AceitarConviteForm
            history={history}
            afterSubmit={data => {
              if (data) {
                history.push('/');
              }
            }}
          />
        </Fundo>
      )}
    </>
  );
};

AceitarConvite.propTypes = {
  history: objectOf(any).isRequired,
};

export default AceitarConvite;

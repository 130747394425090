import { parse } from 'query-string';

const useUrl = history => {
  const { search, pathname } = history.location;

  const removerQuery = (acao, valor) => {
    return search.replace(
      search.indexOf(`&${acao}=${valor}`) === -1
        ? `${acao}=${valor}`
        : `&${acao}=${valor}`,
      '',
    );
  };

  const existeQuery = (acao, valor) => {
    return parse(search)[acao] === `${valor}`;
  };

  const pegarValorQuery = acao => {
    return parse(search)[acao];
  };

  const adicionarQuery = (acao, valor) => {
    const novoSearch = removerQuery(acao, pegarValorQuery(acao));
    const url =
      novoSearch.trim().length > 0 && novoSearch !== '?'
        ? `${pathname}${novoSearch}&${acao}=${valor}`
        : `${pathname}?${acao}=${valor}`;
    history.replace(url);
  };

  return {
    adicionarQuery,
    existeQuery,
    pegarValorQuery,
    removerQuery: (acao, valor) => {
      return history.replace(pathname + removerQuery(acao, valor));
    },
  };
};

export default useUrl;

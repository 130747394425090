import styled from 'styled-components';
import {
  DatePicker as AntDatePicker,
  TimePicker as AntTimePicker,
  Button as AntButton,
  Form as AntForm,
  Input as AntInput,
  Upload as AntUpload,
  Switch as AntSwitch,
} from 'antd';

import colors from '../../styles/colors';

export const Item = styled(AntForm.Item)`
  width: ${({ fluido }) => (fluido === 'true' ? '100%' : '173.5px')};
  .ant-form-item-label {
    label {
      color: #000;
      font-size: 14px;
      text-align: left;
      font-weight: bold;
      @media only screen and (max-device-width: 640px) {
        padding: 0px;
      }
    }
  }
`;

export const Form = styled(AntForm)`
  width: 100%;
`;

export const Button = styled(AntButton)`
  width: 100%;

  background-color: ${colors.primaria};
  color: ${colors.texto};
  border-color: ${colors.primaria};

  &:hover {
    color: ${colors.primaria};
    border-color: ${colors.primaria};
    background-color: ${colors.secundaria};
  }

  &:focus {
    color: ${colors.secundaria};
    border-color: ${colors.primaria};
    background-color: ${colors.primaria};
  }

  @media only screen and (max-width: 640px) {
    background-color: ${colors.secundaria};
    color: #ffff;
    border-color: ${colors.secundaria};
  }

  &:focus {
    color: ${colors.secundaria};
    border-color: ${colors.secundaria};
    background-color: ${colors.primaria};
  }
`;

export const ImagemUpload = styled.img`
  width: inherit;
`;

export const DatePicker = styled(AntDatePicker)`
  width: 100%;
`;

export const TimePicker = styled(AntTimePicker)`
  width: 100%;
`;

export const Grupo = styled.div`
  @media only screen and (min-device-width: 640px) {
    display: flex;
    ${Item}:not(:last-child) {
      margin-right: 8px !important;
    }
  }
`;

export const Input = styled(AntInput)`
  font-size: 25px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  &:hover {
    border-color: ${colors.primaria};
    box-shadow: 0 0 0 2px ${colors.primaria};
  }

  &:focus {
    border-color: ${colors.primaria};
    box-shadow: 0 0 0 2px ${colors.primaria};
  }

  @media only screen and (max-width: 640px) {
    &:focus {
      border-color: ${colors.secundaria};
      box-shadow: 0 0 0 2px ${colors.secundaria};
    }
  }
`;

export const Botao = styled(Button)`
  width: 100%;
  height: 100% !important;
  border-radius: 10px;

  background-color: ${colors.primaria};
  color: ${colors.texto};
  border-color: ${colors.primaria};

  padding: 10px;
  span {
    font-size: 25px;
  }
  &:hover {
    color: ${colors.primaria};
    border-color: ${colors.primaria};
    background-color: ${colors.secundaria};
  }

  &:focus {
    color: ${colors.secundaria};
    border-color: ${colors.primaria};
    background-color: ${colors.primaria};
  }

  @media only screen and (max-width: 640px) {
    background-color: ${colors.secundaria};
    color: #ffff;
    border-color: ${colors.secundaria};
  }

  &:focus {
    color: ${colors.secundaria};
    border-color: ${colors.secundaria};
    background-color: ${colors.primaria};
  }
`;

export const InputFormulario = styled(AntInput)`
  /* font-size: 25px; */
  /* padding: 10px; */
  /* border-radius: 10px; */
  /* text-align: center; */
  &:hover {
    border-color: ${colors.primaria};
    box-shadow: 0 0 0 2px ${colors.primaria};
  }

  &:focus {
    border-color: ${colors.primaria};
    box-shadow: 0 0 0 2px ${colors.primaria};
  }

  @media only screen and (max-width: 640px) {
    &:focus {
      border-color: ${colors.secundaria};
      box-shadow: 0 0 0 2px ${colors.secundaria};
    }
  }
`;

export const Upload = styled(AntUpload)`
  .ant-upload.ant-upload-select-picture-card:hover {
    border-color: ${colors.primaria};
    box-shadow: 0 0 0 2px ${colors.primaria};
  }
`;

export const Switch = styled(AntSwitch)`
  height: 23px !important;
`;

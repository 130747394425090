import { Drawer, Form, Select } from 'antd';
import { func, bool, number } from 'prop-types';

import React, { useState, useEffect } from 'react';

import { useApi } from '../../../hooks';

import Campo from '../../layout/Campo';
import { Button } from '../styles';

const AdicionarPapeisUsuariosForm = ({
  drawerStatus,
  onCloseDrawer,
  afterSubmit,
  usuarioUid,
}) => {
  const [cnpjs, setCnpjs] = useState([]);
  const [roles, setRoles] = useState([]);

  const [form] = Form.useForm();

  const { buscar, criar } = useApi();

  const mountCnpjObjectToSelect = ({ object }) => {
    const cnpjObjectMounted = object.map(({ uid, razaoSocial }) => {
      return {
        value: uid,
        label: razaoSocial,
      };
    });

    return cnpjObjectMounted;
  };

  const mountRolesObjectToSelect = ({ object }) => {
    const mountedRolesObject = object.map(({ uid, nome, ativo }) => {
      if (!ativo) {
        return undefined;
      }

      return {
        value: uid,
        label: nome,
      };
    });

    return mountedRolesObject;
  };

  const handleAddRolesUser = async ({ papel, cnpj }) => {
    const { data } = await criar(
      `/papeisusuarios/`,
      {
        cnpjUid: cnpj,
        papelUid: papel,
        usuarioUid,
      },
      `Papel Adicionado ao usuário`,
    );

    return data;
  };

  useEffect(() => {
    const findAllCnpjs = async () => {
      const { data } = await buscar('/cnpjs');
      const objectCnpjMounted = mountCnpjObjectToSelect({ object: data });
      setCnpjs(objectCnpjMounted);
    };

    const findAllPapeis = async () => {
      const { data } = await buscar('/papeis');
      const objectRolesMounted = mountRolesObjectToSelect({ object: data });
      setRoles(objectRolesMounted);
    };
    findAllCnpjs();
    findAllPapeis();
  }, [buscar]);

  return (
    <>
      <Drawer
        title="Papeis"
        visible={drawerStatus}
        onClose={() => onCloseDrawer()}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={async valeu => {
            await handleAddRolesUser(valeu);
            afterSubmit();
            onCloseDrawer();
          }}
        >
          <Campo name="papel" label="Papeis">
            <Select options={roles} allowClear />
          </Campo>

          <Campo name="cnpj" label="Empresas">
            <Select options={cnpjs} allowClear />
          </Campo>

          <Button htmlType="submit" type="primary">
            Salvar
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

AdicionarPapeisUsuariosForm.propTypes = {
  drawerStatus: bool,
  onCloseDrawer: func,
  afterSubmit: func,
  usuarioUid: number,
};

AdicionarPapeisUsuariosForm.defaultProps = {
  drawerStatus: false,
  onCloseDrawer: null,
  afterSubmit: null,
  usuarioUid: null,
};

export default AdicionarPapeisUsuariosForm;

import styled from 'styled-components';
import { Button, Input as AntInput } from 'antd';
import colors from '../../styles/colors';

export const Input = styled(AntInput)`
  font-size: 25px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  &:hover {
    border-color: ${colors.primaria};
    box-shadow: 0 0 0 2px ${colors.primaria};
  }

  &:focus {
    border-color: ${colors.primaria};
    box-shadow: 0 0 0 2px ${colors.primaria};
  }

  @media only screen and (max-width: 640px) {
    &:focus {
      border-color: ${colors.secundaria};
      box-shadow: 0 0 0 2px ${colors.secundaria};
    }
  }
`;

export const Botao = styled(Button)`
  width: 100%;
  height: 100% !important;
  border-radius: 10px;

  background-color: ${colors.primaria};
  color: ${colors.texto};
  border-color: ${colors.primaria};

  padding: 10px;
  span {
    font-size: 25px;
  }
  &:hover {
    color: ${colors.primaria};
    border-color: ${colors.primaria};
    background-color: ${colors.secundaria};
  }

  &:focus {
    color: ${colors.secundaria};
    border-color: ${colors.primaria};
    background-color: ${colors.primaria};
  }

  @media only screen and (max-width: 640px) {
    background-color: ${colors.secundaria};
    color: #ffff;
    border-color: ${colors.secundaria};
  }

  &:focus {
    color: ${colors.secundaria};
    border-color: ${colors.secundaria};
    background-color: ${colors.primaria};
  }
`;

import { objectOf, any, string } from 'prop-types';

import React from 'react';

import { ContainerIconeVoltar, IconeVoltar, Label } from './style';

const Icone = ({ label, history }) => {
  return (
    <ContainerIconeVoltar onClick={() => history.goBack()}>
      <IconeVoltar />
      <Label>{label}</Label>
    </ContainerIconeVoltar>
  );
};

Icone.propTypes = {
  label: string,
  history: objectOf(any),
};

Icone.defaultProps = {
  label: '',
  history: null,
};

export default Icone;

import React, { useState, useEffect, useCallback } from 'react';
import { any, objectOf } from 'prop-types';
import { Header, Tabela } from '../../components/layout';
import { Button } from '../../components/forms/styles';

import 'antd/dist/antd.css';
import { PapelForm } from '../../components/forms';
import { useApi } from '../../hooks';
import { PAPEL } from '../../components/layout/Tabela/nomesCabecalhos';

import { Container } from '../styles';
import { EXCLUIR } from '../../components/layout/Tabela/nomesAcoes';

const Papeis = ({ history }) => {
  const [drawerOpenClose, setDrawerOpenClose] = useState(false);
  const [roles, setRoles] = useState([]);

  const { buscar, deletar } = useApi();

  const loadRoles = useCallback(async () => {
    const { data } = await buscar('/papeis/');
    setRoles(data);
  }, [buscar]);

  useEffect(() => {
    loadRoles();
  }, [loadRoles]);

  const handleCloseDrawer = () => {
    setDrawerOpenClose(false);
  };

  const handleOpenDrawer = () => {
    setDrawerOpenClose(true);
  };

  const handleDeleteRoles = async ({ uid, nome }) => {
    const { data } = await deletar(
      `/papeis/${uid}`,
      `Papel ${nome} foi excluido`,
    );

    if (data !== null) {
      const newRolesList = roles.filter(role => role.uid !== data.uid);
      setRoles(newRolesList);
    }
  };

  const AddNewRole = () => {
    setDrawerOpenClose(false);
    loadRoles();
  };

  return (
    <>
      <Header
        onBack={() => history.goBack()}
        titulo="Painel de Usuário"
        extra={[
          <Button key="1" type="primary" onClick={() => handleOpenDrawer()}>
            Novo Papel
          </Button>,
        ]}
      />

      <Container>
        <Tabela
          cabecalho={PAPEL}
          dados={roles}
          acoes={[
            {
              titulo: EXCLUIR,
              onClick: dados => handleDeleteRoles(dados),
              confirmacao: true,
            },
          ]}
        />
      </Container>

      <PapelForm
        drawerStatus={drawerOpenClose}
        onCloseDrawer={() => handleCloseDrawer()}
        afterSubmit={resultQuery => AddNewRole({ newRole: resultQuery })}
      />
    </>
  );
};

Papeis.propTypes = {
  history: objectOf(any).isRequired,
};
export default Papeis;

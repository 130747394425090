import React from 'react';
import { objectOf, any } from 'prop-types';
import { useDispatch } from 'react-redux';
import { setContaAtiva } from '../../store/modules/conta/actions';
import { ConfirmarContaForm } from '../../components/forms';
import { useUrl } from '../../hooks';
import {
  BlocoDireito,
  BlocoEsquerdo,
  Container,
  Fundo,
  Imagem,
  H2,
} from '../styles';

import logo from '../../assets/logo/horizontal-s-tag2.png';

import { Desktop } from '../../utils/tamanhoDeTela';

const ConfirmarConta = ({ history }) => {
  const dispatch = useDispatch();
  const { pegarValorQuery } = useUrl(history);

  return (
    // <ImageContainer titulo="Confirmação da conta">
    //   <ConfirmarContaForm
    //     token={pegarValorQuery('token')}
    //     afterSubmit={data => {
    //       if (data) {
    //         dispatch(setContaAtiva(true));
    //         history.push('/');
    //       }
    //     }}
    //   />
    // </ImageContainer>]
    <>
      {Desktop() ? (
        <>
          <Fundo>
            <BlocoEsquerdo>
              <Imagem src={logo} />
            </BlocoEsquerdo>
            <BlocoDireito>
              <H2>Confirmar Conta</H2>
              <Container>
                <ConfirmarContaForm
                  token={pegarValorQuery('token')}
                  afterSubmit={data => {
                    if (data) {
                      dispatch(setContaAtiva(true));
                      history.push('/');
                    }
                  }}
                />
              </Container>
            </BlocoDireito>
          </Fundo>
        </>
      ) : (
        <>
          <Fundo>
            <Imagem src={logo} />
            <H2>Confirmar Conta</H2>
            <ConfirmarContaForm
              token={pegarValorQuery('token')}
              afterSubmit={data => {
                if (data) {
                  dispatch(setContaAtiva(true));
                  history.push('/');
                }
              }}
            />
          </Fundo>
        </>
      )}
    </>
  );
};

ConfirmarConta.propTypes = {
  history: objectOf(any).isRequired,
};

export default ConfirmarConta;

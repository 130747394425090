import styled, { createGlobalStyle } from 'styled-components';
import { Drawer as AntDrawer } from 'antd';
import colors from './colors';

export default createGlobalStyle`

  * {
    box-sizing: border-box;
    font-family: 'Fira Sans', sans-serif !important;
    margin: 0;
    outline: 0;
    padding: 0;
  }

  .ant-switch-checked {
    background-color: ${colors.primaria} !important;
  }

  .ant-layout-sider-children{
    height: 0%;
  }

  aside{
    background-color: ${colors.secundaria} !important;
  }

  //Alterando tamanho da font apresentada em todas as linhas das tabelas no sistema
  table button{
    font-size: 12px !important;
  }

  //Alterando cor dos titulo em detalhes de meus contratos

  /* .daXypG{
    color: ${colors.primaria_fundo_claro} !important
  } */

  //alterando cor do fundo do menu que fica no lado esquerdo
  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: ${colors.secundaria};
    color: '#ffff'
  }


  //alterando cor dos links do menu
  .ant-menu-dark .ant-menu-item:hover,
  .ant-menu-dark .ant-menu-item-active,
  .ant-menu-dark .ant-menu-submenu-active,
  .ant-menu-dark .ant-menu-submenu-open,
  .ant-menu-dark .ant-menu-submenu-selected,
  .ant-menu-dark .ant-menu-submenu-title:hover{
    color: ${colors.primaria};

  }

  .ant-menu-dark .ant-menu-item:hover > a,
  .ant-menu-dark .ant-menu-item-active > a,
  .ant-menu-dark .ant-menu-submenu-active > a,
  .ant-menu-dark .ant-menu-submenu-open > a,
  .ant-menu-dark .ant-menu-submenu-selected > a,
  .ant-menu-dark .ant-menu-submenu-title:hover > a,
  .ant-menu-dark .ant-menu-item:hover > span > a,
  .ant-menu-dark .ant-menu-item-active > span > a,
  .ant-menu-dark .ant-menu-submenu-active > span > a,
  .ant-menu-dark .ant-menu-submenu-open > span > a,
  .ant-menu-dark .ant-menu-submenu-selected > span > a,
  .ant-menu-dark .ant-menu-submenu-title:hover > span > a{
    color: ${colors.primaria};

  }

  //alterando padrão de cores do select globalmente
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
    border-color: ${colors.primaria};
    box-shadow: 0 0 0 2px ${colors.primaria_fundo_claro};
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${colors.primaria};
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${colors.primaria};
  }


  //Alterando cor dos links[tag a]
  a{
    color: ${colors.primaria_fundo_claro} !important
  }

  a:hover{
    color: ${colors.primaria} !important
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: ${colors.primaria_fundo_claro} !important
  }

  .ant-tabs-ink-bar{
    background-color: ${colors.primaria_fundo_claro};
  }


  html, body, #root {
    color: #333;
    height: 100%;
  }

  button {
    height: 45px !important;
    font-size: 18pt;
    color: #fff;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  //alterando cor do spin
  .ant-spin-dot-item {
    background-color: ${colors.primaria_fundo_claro};
  }
`;

export const Drawer = styled(AntDrawer)`
  height: 100%;
`;

export const Icone = styled.div`
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width ?? '40px'};
  background-size: ${({ width }) => width ?? '40px'};
  height: ${({ height }) => height ?? '40px'};
  font-size: 14pt;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor || '#f5f3f1'};
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ absoluto }) =>
    absoluto &&
    `
    position: absolute;
    margin-top: 4px;
    `};
  ${({ imagem }) =>
    imagem &&
    `
    background-image: url(${imagem});
    background-repeat: no-repeat;
    background-position: center;
  `}
`;

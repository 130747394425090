import React from 'react';
import { objectOf, arrayOf, object, any, bool } from 'prop-types';
import { EditOutlined } from '@ant-design/icons';
import { Icone } from '../../../styles/global';
import Sessao from '../Sessao';
import {
  DescritorContainer,
  PanelContainer,
  Item,
  ItemContainer,
  SectionContainer,
  AlterarButton,
  ItemContent,
} from './styles';

const Descritor = ({ propriedades, objeto, semSection, inline }, key) => {
  const renderProp = propriedade => {
    if (!objeto?.[propriedade]) {
      return propriedade in (objeto ?? {}) ? 'Não Informado' : <h4>Não tem</h4>;
    }
    return objeto[propriedade];
  };

  const renderItem = (itens, naoTemSe, customItem, index) => (
    <ItemContainer key={index}>
      {naoTemSe?.(objeto) ? (
        <Item key={index} inline={inline}>
          <h4>Não Tem</h4>
        </Item>
      ) : (
        <>
          {customItem ? (
            <Item>{customItem?.(objeto)}</Item>
          ) : (
            itens?.map(
              (
                {
                  nome,
                  propriedade,
                  custom,
                  naoMostrarSe,
                  semTitulo,
                  aoAlterar,
                  esconderAlteracao,
                  icone,
                  semMargem,
                },
                indexItem,
              ) => {
                return (
                  !naoMostrarSe?.(objeto) && (
                    <Item
                      key={indexItem}
                      inline={inline || icone}
                      margem={semMargem}
                    >
                      <ItemContent
                        icone={icone?.(objeto ?? {})}
                        inline={inline && icone ? 'true' : 'false'}
                      >
                        {!semTitulo &&
                          (propriedade in (objeto ?? {}) || custom) && (
                            <>
                              {typeof nome === 'function' ? (
                                nome(objeto ?? {})
                              ) : (
                                <h4>{nome}</h4>
                              )}
                            </>
                          )}
                        <span>
                          {custom?.(objeto ?? {}) ?? renderProp(propriedade)}
                        </span>
                        {aoAlterar && !esconderAlteracao && (
                          <AlterarButton onClick={aoAlterar}>
                            Alterar <EditOutlined />
                          </AlterarButton>
                        )}
                      </ItemContent>
                      {icone?.(objeto ?? {}) && (
                        <Icone imagem={icone?.(objeto ?? {})} />
                      )}
                    </Item>
                  )
                );
              },
            )
          )}
        </>
      )}
    </ItemContainer>
  );

  return (
    <DescritorContainer inline={inline} key={key}>
      <PanelContainer quantidadeColunas={propriedades.length}>
        {propriedades?.map(
          ({ titulo, itens, naoMostrarSe, naoTemSe, custom }, index) => {
            if (!naoMostrarSe?.(objeto)) {
              return !semSection ? (
                <SectionContainer key={index}>
                  <Sessao semMargem="true" titulo={titulo}>
                    {renderItem(itens, naoTemSe, custom)}
                  </Sessao>
                </SectionContainer>
              ) : (
                renderItem(itens, naoTemSe, custom, index)
              );
            }
            return null;
          },
        )}
      </PanelContainer>
    </DescritorContainer>
  );
};

Descritor.propTypes = {
  semSection: bool,
  inline: bool,
  propriedades: arrayOf(object).isRequired,
  objeto: objectOf(any),
};

Descritor.defaultProps = {
  semSection: null,
  inline: null,
  objeto: {},
};

export default Descritor;

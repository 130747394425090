import styled from 'styled-components';
import { Input as AntInput, Button } from 'antd';
import { LeftSquareFilled } from '@ant-design/icons';
import colors from '../../../styles/colors';

export const Input = styled(AntInput)`
  font-size: 25px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  &:hover {
    border-color: ${colors.primaria};
    box-shadow: 0 0 0 2px ${colors.primaria};
  }

  &:focus {
    border-color: ${colors.primaria};
    box-shadow: 0 0 0 2px ${colors.primaria};
  }

  @media only screen and (max-width: 640px) {
    &:focus {
      border-color: ${colors.secundaria};
      box-shadow: 0 0 0 2px ${colors.secundaria};
    }
  }
`;

export const Botao = styled(Button)`
  width: 100%;
  height: 100% !important;
  border-radius: 10px;

  background-color: ${colors.primaria};
  color: ${colors.texto};
  border-color: ${colors.primaria};

  padding: 10px;
  span {
    font-size: 25px;
  }
  &:hover {
    color: ${colors.primaria};
    border-color: ${colors.primaria};
    background-color: ${colors.secundaria};
  }

  &:focus {
    color: ${colors.secundaria};
    border-color: ${colors.primaria};
    background-color: ${colors.primaria};
  }

  @media only screen and (max-width: 640px) {
    background-color: ${colors.secundaria};
    color: #ffff;
    border-color: ${colors.secundaria};
  }

  &:focus {
    color: ${colors.secundaria};
    border-color: ${colors.secundaria};
    background-color: ${colors.primaria};
  }
`;

export const Link = styled.a`
  font-size: 16px;
  color: #ffff;
  width: 100%;
  text-align: center;
  &:hover {
    color: ${colors.primaria_fundo_claro};
  }

  @media only screen and (max-width: 640px) {
    color: ${colors.secundaria};
  }
`;

export const Container = styled.div`
  padding: 10px;
  width: 100%;
  height: 50vh;
  label {
    color: #ffff !important;
    font-size: 20px;
  }

  /* .fbEhrq .ant-form-item-label label {
    color: #ffff !important;
    font-size: 20px;
  } */
  @media only screen and (max-width: 640px) {
    .fbEhrq .ant-form-item-label label {
      color: ${colors.secundaria} !important;
    }
  }
`;

export const OptionsContainer = styled.div`
  margin-top: 20px !important;
  padding-top: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 10px;
  @media only screen and (max-device-width: 640px) {
    width: 100%;
  }

  /* button {
    background: transparent;
    width: fit-content;
    border: none;
    outline: none;
    margin: 0px;
    padding: 0px;
    font-weight: normal;
    font-size: 14px;
    height: 22px;
  } */

  /* button:disabled {
    cursor: unset;
    color: rgba(0, 0, 0, 0.25);
  } */
`;

export const Titulo = styled.h2`
  text-align: center;
  color: #ffff;
  @media only screen and (max-device-width: 640px) {
    color: ${colors.secundaria};
  }
`;
export const Label = styled.label`
  color: #ffff !important;
  margin-left: 5px;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  transition: color 0.5s;

  @media only screen and (max-device-width: 640px) {
    color: ${colors.secundaria};
  }
`;

export const ContainerIconeVoltar = styled.div`
  position: absolute;
  top: -200px;
  align-items: center;
  display: flex;

  &:hover label {
    color: ${colors.primaria};
  }

  &:hover span {
    border: 1px solid ${colors.primaria};
    box-shadow: 1px ${colors.primaria};
  }

  @media only screen and (max-device-width: 640px) {
    top: -280px;
  }
`;

export const IconeVoltar = styled(LeftSquareFilled)`
  font-size: 30px;
  color: ${colors.primaria} !important;
  border: 1px solid ${colors.secundaria};
  transition: border 0.5s;
  cursor: pointer;

  &:hover {
    border: 1px solid ${colors.primaria};
    box-shadow: 1px ${colors.primaria};
  }

  @media only screen and (max-device-width: 640px) {
    color: ${colors.secundaria} !important;
    border: 1px solid ${colors.primaria};

    top: -280px;

    &:focus {
      border: 1px solid ${colors.secundaria};
      box-shadow: 1px ${colors.secundaria};
    }
  }
`;

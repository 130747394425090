import React from 'react';
import { any, arrayOf, oneOfType, objectOf, string } from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import {
  Container,
  Wrapper,
  Footer,
  Body,
  LinksContainer,
  Link,
  TextContainer,
  LogoContainer,
  FormContainer,
  TituloContainer,
  Card,
} from './styles';

const ImageContainer = ({ children, titulo }) => {
  const { loading } = useSelector(
    ({ sistemaReducer }) => sistemaReducer,
    shallowEqual,
  );

  return (
    <Container>
      <Body>
        <LogoContainer />
        <Wrapper>
          <Card>
            <TituloContainer>
              <span>{titulo}</span>
            </TituloContainer>
            <FormContainer>{children}</FormContainer>
          </Card>
        </Wrapper>
      </Body>
      <Footer>
        <TextContainer>
          Sistema{' '}
          <Link
            href="https://www.vexta.com.br"
            target="blank"
            disabled={loading}
          >
            Vexta
          </Link>{' '}
          para Gestão de Oficinas de Costura
        </TextContainer>
        <LinksContainer>
          <Link
            href="http://lib.vexta.com.br/legal/termos/"
            target="blank"
            disabled={loading}
          >
            Termos de Uso
          </Link>
          <Link
            href="http://lib.vexta.com.br/privacidade/"
            target="blank"
            disabled={loading}
          >
            Política de Privacidade
          </Link>
        </LinksContainer>
      </Footer>
    </Container>
  );
};

ImageContainer.propTypes = {
  children: oneOfType([arrayOf(any), objectOf(any)]),
  titulo: string.isRequired,
};

ImageContainer.defaultProps = {
  children: null,
};

export default ImageContainer;

import React, { useCallback, useEffect, useState } from 'react';

import { removeAll } from 'character-remover/src/CharacterRemover';
import { any, objectOf } from 'prop-types';
import { Container, Titulo } from '../styles';
import { Header, Tabela } from '../../components/layout';
import { USUARIO } from '../../components/layout/Tabela/nomesCabecalhos';
import { useApi } from '../../hooks';
import { Select, Row, Search, Col } from './styled';
import { Button } from '../../components/forms/styles';

import AdicionarUsuarioForm from '../../components/forms/AdicionarUsuarioForm';
import { EXCLUIR } from '../../components/layout/Tabela/nomesAcoes';

const GerenciarUsuarios = ({ history }) => {
  const { buscar, deletar } = useApi();
  const [userWithCnpjs, setUserWithCnpjs] = useState([]);
  const [cnpjsList, setCnpjsList] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [drawerStatus, setDrawerStatus] = useState(false);

  const MountObjectTableUserCnpj = ({ userContractObject }) => {
    const userContractMountedObject = userContractObject.map(
      ({ uid, nome, email, cnpjs }) => {
        let mountStringManyCnpjs = '';

        if (!cnpjs.length) {
          return { uid, nome, email, cnpj: 'Não possui CNPJ atribuído' };
        }

        cnpjs.forEach((cnpj, index) => {
          if (index === 0) {
            mountStringManyCnpjs += `${cnpj.razaoSocial}`;
          } else {
            mountStringManyCnpjs += `/${cnpj.razaoSocial}`;
          }
        });

        return { uid, nome, email, cnpj: mountStringManyCnpjs };
      },
    );

    return userContractMountedObject;
  };

  const UserWithCnpjList = useCallback(async () => {
    const { data } = await buscar(`/usuarios/listarUsuariosComCnpj`);
    const objectTableUserContract = MountObjectTableUserCnpj({
      userContractObject: data,
    });

    setUserWithCnpjs(objectTableUserContract);
  }, [buscar]);

  const MountObjectSelectCnpjs = ({ cnpjsObject }) => {
    const objectSelectCnpjs = cnpjsObject.map(({ uid, razaoSocial, cnpj }) => {
      return { value: uid, label: `${razaoSocial} - ${cnpj}` };
    });
    return objectSelectCnpjs;
  };

  const SearchUserWithCnpjUsingCnpjUid = async ({ cnpjUid }) => {
    const { data } = await buscar(`/usuarios/listarUsuariosComCnpj/${cnpjUid}`);
    const objectTableUserContractMounted = MountObjectTableUserCnpj({
      userContractObject: data,
    });

    setUserWithCnpjs(objectTableUserContractMounted);
  };

  const SearchUserWithCnpjUsingNameUser = async () => {
    if (!searchString) {
      await UserWithCnpjList();
      return;
    }

    const { data } = await buscar(
      `/usuarios/pesquisarUsuariosComCnpjUsandoNomeUsuario/${searchString}`,
    );

    const objectTableUserCnpjMounted = MountObjectTableUserCnpj({
      userContractObject: data,
    });

    setUserWithCnpjs(objectTableUserCnpjMounted);
  };

  const handleVerifySpecialCharacter = ({ str }) => {
    if (!str.length) {
      SearchUserWithCnpjUsingNameUser();
    }
    setSearchString(removeAll(str));
  };

  const handleOpenDrawer = () => {
    setDrawerStatus(true);
  };

  const handleCloseDrawer = () => {
    setDrawerStatus(false);
  };

  const handleDeleteUser = async ({ usuarioUid, nomeUsuario }) => {
    const { data } = await deletar(
      `/usuarios/${usuarioUid}`,
      `Usuario ${nomeUsuario} foi excluido com sucesso`,
    );

    if (data !== null) {
      const newArrayUser = userWithCnpjs.filter(user => user.uid !== data.uid);
      setUserWithCnpjs(newArrayUser);
    }
  };

  useEffect(() => {
    const allCnpjsList = async () => {
      const { data } = await buscar('/cnpjs/');
      const objectSelectCnpjsMounted = MountObjectSelectCnpjs({
        cnpjsObject: data,
      });
      setCnpjsList(objectSelectCnpjsMounted);
    };

    UserWithCnpjList();
    allCnpjsList();
  }, [buscar, UserWithCnpjList]);

  return (
    <>
      <Header
        onBack={() => history.goBack()}
        titulo="Painel de Usuário"
        extra={[
          <Button key="1" type="primary" onClick={() => handleOpenDrawer()}>
            Novo Usuário
          </Button>,
        ]}
      />
      <Container>
        <Titulo>Painel</Titulo>
        <Row>
          <Col span={14}>
            <Search
              enterButton
              placeholder="Pesquisar usuários por nome"
              onChange={({ target }) =>
                handleVerifySpecialCharacter({ str: target.value })
              }
              onSearch={() => SearchUserWithCnpjUsingNameUser()}
              value={searchString}
              size="large"
            />
          </Col>
          <Col span={10}>
            <Select
              size="large"
              allowClear
              onClear={UserWithCnpjList}
              placeholder="Filtrar Cnpjs"
              options={cnpjsList}
              onSelect={uid => SearchUserWithCnpjUsingCnpjUid({ cnpjUid: uid })}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Tabela
              cabecalho={USUARIO}
              dados={userWithCnpjs}
              celulaSelecionada={({ uid }) =>
                history.push(`/gerenciar-usuario/${uid}`)
              }
              key={({ uid }) => uid}
              paginacao
              acoes={[
                {
                  titulo: EXCLUIR,
                  onClick: dados =>
                    handleDeleteUser({
                      nomeUsuario: dados.nome,
                      usuarioUid: dados.uid,
                    }),
                  confirmacao: true,
                },
              ]}
            />
          </Col>
        </Row>
      </Container>

      <AdicionarUsuarioForm
        drawerStatus={drawerStatus}
        onCloseDrawer={() => handleCloseDrawer()}
        afterSubmit={async () => UserWithCnpjList()}
      />
    </>
  );
};

GerenciarUsuarios.propTypes = {
  history: objectOf(any).isRequired,
};

export default GerenciarUsuarios;

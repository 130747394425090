import { any, objectOf } from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { shallowEqual, useSelector } from 'react-redux';
import { Header, Tabela } from '../../../components/layout';
import {
  AdicionarPapeisUsuariosForm,
  AdicionarUsuarioForm,
} from '../../../components/forms';
import { ButtonPlus, TabelaContainer } from './styled';
import { Button } from '../styled';
import { useApi } from '../../../hooks';
import { PAPEISUSUARIO } from '../../../components/layout/Tabela/nomesCabecalhos';
import { EXCLUIR } from '../../../components/layout/Tabela/nomesAcoes';
import Descritor from '../../../components/layout/Descritor';

const detalhes = [
  {
    titulo: 'Dados Usuário',
    itens: [
      { nome: 'Nome', propriedade: 'nome' },
      { nome: 'SobreNome', propriedade: 'sobrenome' },
    ],
  },

  {
    titulo: 'Contato',
    itens: [
      {
        nome: 'E-mail',
        // eslint-disable-next-line react/prop-types
        custom: ({ email }) => email && <a href={`mailto:${email}`}>{email}</a>,
      },
    ],
  },

  {
    titulo: 'Status',
    itens: [
      {
        nome: 'Situação',
        // eslint-disable-next-line react/prop-types
        propriedade: 'situacao',
      },
    ],
  },
];

const Detalhes = ({ history, match }) => {
  const [papersUser, setPapersUser] = useState([]);
  const [papeisUsuarioDrawerStatus, setPapeisUsuarioDrawerStatus] = useState(
    false,
  );
  const [usuarioDrawerStatus, setUsuarioDrawerStatus] = useState(false);

  const [user, setUser] = useState({});

  const { deletar, buscar } = useApi();

  const { loading } = useSelector(
    ({ sistemaReducer }) => sistemaReducer,
    shallowEqual,
  );

  const mountPapersUser = ({ data }) => {
    const paperUserMounted = data.map(pUser => {
      return {
        uid: pUser.uid,
        cargo: pUser.cargo,
        cnpj: pUser.cnpj.razaoSocial,
      };
    });

    return paperUserMounted;
  };

  const { id } = match.params;

  const loadUserData = useCallback(async () => {
    if (!id) {
      return;
    }
    const { data } = await buscar(`/usuarios/${id}`);
    setUser(data);
  }, [buscar, id]);

  const loadPermissionsUser = useCallback(async () => {
    const { data } = await buscar(`/papeisusuarios/${id}`);
    const mountedPapersUser = mountPapersUser({ data });
    setPapersUser(mountedPapersUser);
  }, [buscar, id]);

  useEffect(() => {
    loadPermissionsUser();
    loadUserData();
  }, [loadPermissionsUser, loadUserData]);

  const handleDeleteRoleUser = async ({ paperUserObject }) => {
    const { uid, cargo } = paperUserObject;
    const newPaperUserArray = papersUser.filter(paper => paper.uid !== uid);
    await deletar(
      `/papeisusuarios/${uid}`,
      `Papel ${cargo} atribuído ao usuário foi removido`,
    );
    setPapersUser(newPaperUserArray);
  };

  const handleOpenDrawerPapeisUsuario = () => {
    setPapeisUsuarioDrawerStatus(true);
  };

  const handleCloseDrawerPapeisUsuario = () => {
    setPapeisUsuarioDrawerStatus(false);
  };

  const handleOpenDrawerUsuarioForm = () => {
    setUsuarioDrawerStatus(true);
  };

  const handleCloseDrawerUsuarioForm = () => {
    setUsuarioDrawerStatus(false);
  };

  return (
    <>
      <Header
        onBack={() => history.goBack()}
        titulo={`${user.nome} ${user.sobrenome}`}
        classe="Detalhes Usuário"
        extra={[
          <Button
            disabled={loading}
            onClick={() => handleOpenDrawerUsuarioForm()}
            key="1"
            type="primary"
          >
            Alterar
          </Button>,
        ]}
      >
        <Descritor propriedades={detalhes} objeto={user} />
      </Header>

      <TabelaContainer>
        <ButtonPlus onClick={() => handleOpenDrawerPapeisUsuario()}>
          <PlusOutlined /> Adicionar Papel
        </ButtonPlus>

        <Tabela
          titulo="Papeis atribuídos ao usuário"
          cabecalho={PAPEISUSUARIO}
          dados={papersUser}
          acoes={[
            {
              titulo: EXCLUIR,
              onClick: dados =>
                handleDeleteRoleUser({ paperUserObject: dados }),
              confirmacao: true,
            },
          ]}
        />
      </TabelaContainer>

      <AdicionarPapeisUsuariosForm
        drawerStatus={papeisUsuarioDrawerStatus}
        onCloseDrawer={() => handleCloseDrawerPapeisUsuario()}
        afterSubmit={async () => loadPermissionsUser()}
        usuarioUid={Number(id)}
      />

      <AdicionarUsuarioForm
        drawerStatus={usuarioDrawerStatus}
        afterSubmit={() => loadUserData()}
        onCloseDrawer={() => handleCloseDrawerUsuarioForm()}
        user={user}
      />
    </>
  );
};

Detalhes.propTypes = {
  history: objectOf(any).isRequired,
  match: objectOf(any).isRequired,
};

export default Detalhes;

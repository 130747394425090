import React, { useEffect, useState } from 'react';

import { func } from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { Campo, Row, Container } from './styled';

import { useApi } from '../../hooks';

const BarraDePesquisa = ({ onchange, onclear }) => {
  const { uid: usuarioUid } = useSelector(
    ({ contaReducer }) => contaReducer,
    shallowEqual,
  );

  const [permissaoAdmin, setPermissaoAdmin] = useState(false);
  const [texto, setTexto] = useState('');

  const { buscar } = useApi();

  useEffect(() => {
    const VerificarSuperUsuario = async () => {
      const { data } = await buscar(
        `/usuarios/verificarAdminGlobal/${usuarioUid}`,
      );
      setPermissaoAdmin(data);
    };

    VerificarSuperUsuario();
  }, [buscar, usuarioUid]);

  const filtrar = event => {
    const { value } = event.target;

    if (value.length) {
      onchange(value);
    }

    if (!value.length) {
      onclear();
    }
  };

  const verificarCaracteres = event => {
    const { value } = event.target;

    const result = value.replace('/', '');
    setTexto(result);
  };

  return (
    <Container ativo={permissaoAdmin}>
      <Row>
        <Campo
          // onChange={filtrar}
          onChange={event => {
            verificarCaracteres(event);
            filtrar(event);
          }}
          value={texto}
          placeholder="Pesquisar contrato"
        />
      </Row>
    </Container>
  );
};

BarraDePesquisa.propTypes = {
  onchange: func.isRequired,
  onclear: func.isRequired,
};

export default BarraDePesquisa;

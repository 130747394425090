import { SET_TOKEN, SET_TOKEN_VALIDO, SET_LOADING } from './operacoes';

export const setLoading = payload => {
  return { type: SET_LOADING, payload };
};

export const setToken = valor => {
  return { type: SET_TOKEN, valor };
};

export const setTokenValido = valor => {
  return { type: SET_TOKEN_VALIDO, valor };
};

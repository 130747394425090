import { SET_LOADING, SET_TOKEN, SET_TOKEN_VALIDO } from './operacoes';

const INITIAL_STATE = {
  token: null,
  tokenValido: true,
  loading: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  const { type, valor } = action;
  switch (type) {
    case SET_TOKEN:
      return { ...state, token: valor };
    case SET_TOKEN_VALIDO:
      return { ...state, tokenValido: valor };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

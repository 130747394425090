import styled from 'styled-components';
import colors from '../../../styles/colors';

export const P = styled.p`
  color: ${colors.secundaria};
  cursor: default;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    color: ${colors.primaria};
    cursor: default;
  }
`;

import React from 'react';
import { Spin } from 'antd';
import { objectOf, any } from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { Campo } from '../../components/layout';
import { useApi, useUrl } from '../../hooks';
import { Form } from '../../components/forms/styles';
import logo from '../../assets/logo/horizontal-s-tag2.png';
import { Fundo, BlocoEsquerdo, BlocoDireito, Imagem, H2 } from '../styles';

import { Botao, Input } from './styled';
import { Desktop } from '../../utils/tamanhoDeTela';

const RedefinirSenha = ({ history }) => {
  const { pegarValorQuery } = useUrl(history);
  const token = pegarValorQuery('token');
  const [form] = Form.useForm();
  const { alterar } = useApi();
  const { loading } = useSelector(
    ({ sistemaReducer }) => sistemaReducer,
    shallowEqual,
  );

  const redefinirSenha = async ({ senha }) => {
    const { data } = await alterar(
      '/usuarios/redefinir',
      {
        senha,
        token,
      },
      'Senha redefinida com sucesso.',
    );
    if (data) {
      history.push('/');
    }
  };

  return (
    // <ImageContainer titulo="Alterar sua senha">
    //   <Form
    //     onFinish={redefinirSenha}
    //     form={form}
    //     layout="vertical"
    //     hideRequiredMark
    //     name="controlled-form"
    //   >
    //     <Spin spinning={loading}>
    //       <Campo fluido obrigatorio name="senha" label="Nova Senha">
    //         <Input type="password" placeholder="Digite sua nova senha" />
    //       </Campo>

    //       <Campo fluido>
    //         <button type="button" onClick={redefinirSenha}>
    //           Redefinir senha
    //         </button>
    //       </Campo>
    //     </Spin>
    //   </Form>
    // </ImageContainer>

    <>
      {Desktop() ? (
        <>
          <Fundo>
            <BlocoEsquerdo>
              <Imagem src={logo} />
            </BlocoEsquerdo>
            <BlocoDireito>
              <H2>Redefinir senha </H2>
              <Form
                onFinish={redefinirSenha}
                form={form}
                layout="vertical"
                hideRequiredMark
                name="controlled-form"
              >
                <Spin spinning={loading}>
                  <Campo fluido obrigatorio name="senha" label="Nova Senha">
                    <Input
                      type="password"
                      placeholder="Digite sua nova senha"
                    />
                  </Campo>

                  <Campo fluido>
                    <Botao type="button" onClick={redefinirSenha}>
                      Redefinir senha
                    </Botao>
                  </Campo>
                </Spin>
              </Form>
            </BlocoDireito>
          </Fundo>
        </>
      ) : (
        <>
          <Fundo>
            <Imagem src={logo} />
            <H2>Redefinir senha </H2>

            <Form
              onFinish={redefinirSenha}
              form={form}
              layout="vertical"
              hideRequiredMark
              name="controlled-form"
            >
              <Spin spinning={loading}>
                <Campo fluido obrigatorio name="senha" label="Nova Senha">
                  <Input type="password" placeholder="Digite sua nova senha" />
                </Campo>

                <Campo fluido>
                  <Botao type="button" onClick={redefinirSenha}>
                    Redefinir senha
                  </Botao>
                </Campo>
              </Spin>
            </Form>
          </Fundo>
        </>
      )}
    </>
  );
};

RedefinirSenha.propTypes = {
  history: objectOf(any).isRequired,
};

export default RedefinirSenha;

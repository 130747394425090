import React, { useState } from 'react';
import { Spin } from 'antd';
import { any, func, objectOf } from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { useApi } from '../../../hooks';
import { Campo, Icone } from '../../layout';
import { onFinish } from '../functions';
import {
  OptionsContainer,
  Input,
  Container,
  Botao,
  Link,
  Titulo,
} from './styles';
import { Form } from '../styles';

const LoginForm = ({ afterSubmit, history }) => {
  const { criar } = useApi();
  const [form] = Form.useForm();
  const { loading } = useSelector(
    ({ sistemaReducer }) => sistemaReducer,
    shallowEqual,
  );
  const [mostrar, setMostrar] = useState(false);
  const [emailLocalizado, setEmailLocalizado] = useState('');

  const login = async values => {
    const { senha } = values;
    const { data } = await criar('/sessoes', {
      senha,
      email: emailLocalizado,
    });
    return { data, email: emailLocalizado };
  };

  const buscarEmail = async values => {
    const { email } = values;
    const { data } = await criar('/usuarios/buscar-email', { email });
    if (data) {
      setEmailLocalizado(email);
      setMostrar(true);
    }
    // setMostrar(true);
    // setEmailLocalizado(email);
  };

  const voltarParaLogin = () => {
    setEmailLocalizado('');
    setMostrar(false);
    form.resetFields();
  };

  return (
    <Container>
      {mostrar && (
        <Icone
          history={history}
          label="Voltar"
          onClick={() => voltarParaLogin()}
        />
      )}

      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        onFinish={values => {
          if (!mostrar) {
            buscarEmail(values);
          }

          if (mostrar) {
            onFinish(values, login, null, afterSubmit, form.resetFields);
          }
        }}
      >
        <Spin spinning={loading}>
          {mostrar ? (
            <>
              <Titulo>Senha para {emailLocalizado}</Titulo>
            </>
          ) : (
            <Campo name="email" obrigatorio fluido label="USUÁRIO">
              <Input
                type="email"
                placeholder="email@empresa.com.br"
                maxLength={50}
              />
            </Campo>
          )}

          {!mostrar || (
            <Campo name="senha" obrigatorio fluido label="SENHA">
              <Input type="password" placeholder="**********" />
            </Campo>
          )}

          <Botao type="primary" htmlType="submit">
            {!mostrar ? 'Continuar' : 'Login'}
          </Botao>
        </Spin>
      </Form>

      <OptionsContainer>
        {mostrar || (
          <Link
            onClick={() => {
              history.push('/cadastrar');
            }}
          >
            Ainda não sou cliente. Quero criar minha conta
          </Link>
        )}

        {!mostrar || (
          <Link
            onClick={() => {
              history.push('/esqueci-minha-senha');
            }}
          >
            Esqueci minha senha
          </Link>
        )}
      </OptionsContainer>
    </Container>
  );
};

LoginForm.propTypes = {
  history: objectOf(any).isRequired,
  afterSubmit: func,
};

LoginForm.defaultProps = {
  afterSubmit: null,
};

export default LoginForm;

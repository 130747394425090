export default {
  titulo: '',
  cabecalho: {
    desktop: [
      {
        titulo: 'Uid',
        atributo: 'uid',
      },
      {
        titulo: 'Nome',
        atributo: 'nome',
      },
      {
        titulo: 'Email',
        atributo: 'email',
      },
      {
        titulo: 'Cnpj',
        atributo: 'cnpj',
      },
    ],
    mobile: [
      {
        titulo: 'Nome',
        atributo: 'nome',
      },
      {
        titulo: 'Email',
        atributo: 'email',
      },
    ],
  },
};

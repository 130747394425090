import React from 'react';
import { message, Spin } from 'antd';
import { func, string } from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { useApi } from '../../../hooks';
import { Campo } from '../../layout';
import { onFinish } from '../functions';
import { Form, Input, Botao } from '../styles';

const ConfirmarContaForm = ({ token, afterSubmit }) => {
  const [form] = Form.useForm();
  const { alterar } = useApi();
  const { loading } = useSelector(
    ({ sistemaReducer }) => sistemaReducer,
    shallowEqual,
  );

  const confirmarConta = async ({ senha, confirmacaoSenha }) => {
    if (senha === confirmacaoSenha) {
      const { data } = await alterar('/usuarios/confirmar-conta', {
        token,
        senha,
      });
      return data;
    }
    return message.error('Ambas as senhas deverão ser idênticas!');
  };

  return (
    <>
      <Form
        onFinish={values => {
          onFinish(
            {
              ...values,
              objectUid: 1,
            },
            null,
            confirmarConta,
            afterSubmit,
            form.resetFields,
          );
        }}
        form={form}
        layout="vertical"
        hideRequiredMark
      >
        <Spin spinning={loading}>
          <Campo name="senha" fluido obrigatorio label="Crie uma Senha">
            <Input type="password" placeholder="********" />
          </Campo>
          <Campo
            name="confirmacaoSenha"
            fluido
            obrigatorio
            label="Confirme a Senha"
          >
            <Input type="password" placeholder="********" />
          </Campo>
          <Botao type="submit" htmlType="submit">
            Confirmar
          </Botao>
        </Spin>
      </Form>
    </>
  );
};

ConfirmarContaForm.propTypes = {
  token: string.isRequired,
  afterSubmit: func,
};

ConfirmarContaForm.defaultProps = {
  afterSubmit: null,
};

export default ConfirmarContaForm;

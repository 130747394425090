import styled from 'styled-components';
import { Button as AntButton } from 'antd';
import colors from '../../styles/colors';

// export const Container = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   height: 100%;
//   max-width: 100%;

//   img {
//     height: auto;
//     width: 100%;
//   }

//   button {
//     background-color: ${corPrimaria};
//     border: none;
//     border-radius: 3px;
//     color: #333;
//     cursor: pointer;
//     font-weight: 600;
//     height: 40px;
//     margin: 0.5rem;
//     width: 300px;

//     &:hover {
//       background-color: ${darken(0.05, corPrimaria)};
//       transition: 0.5s;
//     }
//   }

//   input {
//     background: #fafafa;
//     border: 1px solid #e5e5e5;
//     border-radius: 3px;
//     height: 40px;
//     margin: 0 0.5rem 0.5rem 0.5rem;
//     padding: 1rem;
//     width: 300px;

//     &::placeholder {
//       font-family: 'Source Sans Pro', sans-serif;
//     }
//   }

//   label {
//     color: #555;
//     font-size: 0.75rem;
//     margin-bottom: 0.25rem;
//     padding-left: 0.5rem;
//     text-align: left;
//   }
// `;

export const Fundo = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.secundaria};
  display: flex;
  align-items: center !important;
  justify-content: center;
`;

export const ContainerButton = styled.div`
  width: 100%;
  height: 20vh;
  background-color: ${colors.primaria};
  padding: 20px;
  display: inline-block;
  align-items: center !important;
  justify-content: center;
  border-radius: 10px;
`;

export const Titulo = styled.h2`
  color: ${colors.secundaria};
  text-align: center;
  padding: 5px;
`;

export const Botao = styled(AntButton)`
  width: 100%;
  background-color: ${colors.secundaria};
  border-color: ${colors.secundaria};
  color: #ffff !important;
  border-radius: 10px;

  &:hover {
    background-color: ${colors.primaria};
    border-color: ${colors.secundaria};
    color: ${colors.texto} !important;
  }
`;

// export const Wrapper = styled.div`
//   align-items: center;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   min-width: 300px;
//   width: 500px;
// `;

// export const Imagem = styled.img`
//   border-radius: 4.4042e7px 0px 0px 0px;
//   transform: matrix(1, 0, 0, -1, 0, 0);
//   max-width: ${`${document.documentElement.clientWidth - 500}px`};
// `;

// export const Separador = styled.div`
//   align-items: center;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   width: 300px;

//   section {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: center;
//   }

//   input[type='checkbox'] {
//     background: #fafafa;
//     border: 1px solid #e5e5e5;
//     border-radius: 3px;
//     height: 20px;
//     margin: 0;
//     width: 20px;
//   }

//   label {
//     font-size: 0.85rem;
//     margin-bottom: 0;
//   }

//   a {
//     color: #555555;
//     font-size: 0.85rem;
//     font-weight: 600;
//   }
// `;

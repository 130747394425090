import styled from 'styled-components';
import { LeftSquareFilled } from '@ant-design/icons';
import colors from '../../../styles/colors';

export const ContainerIconeVoltar = styled.div`
  position: absolute;
  top: 50px;
  align-items: center;
  display: flex;
  margin-bottom: 40px;

  &:hover label {
    color: ${colors.primaria};
  }

  &:hover span {
    border: 1px solid ${colors.primaria};
    box-shadow: 1px ${colors.primaria};
  }

  @media only screen and (max-device-width: 640px) {
    top: -280px;
  }
`;

export const IconeVoltar = styled(LeftSquareFilled)`
  font-size: 30px;
  color: ${colors.primaria} !important;
  border: 1px solid ${colors.secundaria};
  transition: border 0.5s;
  cursor: pointer;

  &:hover {
    border: 1px solid ${colors.primaria};
    box-shadow: 1px ${colors.primaria};
  }

  @media only screen and (max-device-width: 640px) {
    color: ${colors.secundaria} !important;
    border: 1px solid ${colors.primaria};

    top: -280px;

    &:focus {
      border: 1px solid ${colors.secundaria};
      box-shadow: 1px ${colors.secundaria};
    }
  }
`;

export const Label = styled.label`
  color: #ffff !important;
  margin-left: 5px !important;
  font-size: 15px !important;
  text-align: center !important;
  cursor: pointer;
  transition: color 0.5s;

  @media only screen and (max-device-width: 640px) {
    color: ${colors.secundaria};
  }
`;

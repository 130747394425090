import styled from 'styled-components';
import { Menu as AntMenu, Layout as AntLayout } from 'antd';
import { Link as DomLink } from 'react-router-dom';

export const Sider = styled(AntLayout.Sider)`
  width: 100px !important;
  @media only screen and (max-device-width: 640px) {
    ${({ colapsar }) =>
      colapsar === 'false' &&
      `
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    `}
  }
`;

export const Layout = styled(AntLayout)`
  @media only screen and (max-device-width: 640px) {
    display: ${({ menucolapsado }) => menucolapsado === 'false' && 'block'};
  }
`;

export const Menu = styled(AntMenu)`
  display: ${({ esconder }) => (esconder === 'true' ? 'none' : 'block')};
  height: 100%;
  min-height: 100vh;
  @media only screen and (min-device-width: 640px) {
    padding-top: 20px;
  }
  @media only screen and (max-device-width: 640px) {
    display: grid;
    justify-content: center;
  }
`;

export const Item = styled(Menu.Item)`
  height: 30px !important;
  display: flex;
  align-items: center;
  transition: 0.3s linear;
`;

export const Avatar = styled(Menu.Item)`
  @media only screen and (max-device-width: 640px) {
    position: ${({ colapsar }) => colapsar === 'false' && 'unset !important'};
  }
  @media only screen and (min-device-width: 640px) {
    position: absolute !important;
  }
  bottom: 0;
  width: inherit;
  max-width: 200px !important;
  background-color: transparent !important;
  height: 100px;
  margin-bottom: 20px !important;
  display: flex;
  justify-content: center;
  cursor: default;

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }

  div {
    display: flex;
    align-content: center;
  }

  ${Item} {
    height: 40px !important;
    margin: 0px;
  }
`;

export const SubMenu = styled(Menu)`
  background: transparent;
  border: 0;
  display: ${({ esconder }) => (esconder === 'true' ? 'none' : 'block')};
  @media only screen and (max-device-width: 640px) {
    display: ${({ esconder, colapsar }) => {
      if (colapsar === 'true') {
        return 'none';
      }
      return esconder === 'true' ? 'grid' : 'block';
    }};
  }
`;

export const Link = styled(DomLink)`
  font-size: 12px;
`;

export const Content = styled(Layout.Content)`
  overflow: unset !important;
`;

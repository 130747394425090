import styled from 'styled-components';
import { Row as antdRow, Col as antdCol } from 'antd';

export const Row = styled(antdRow)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Col = styled(antdCol)`
  box-sizing: border-box;
  padding: 10px;
`;

export const SubTitulo = styled.h3``;

import React, { useState, useEffect } from 'react';
import { bool, oneOfType, array, object } from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  MenuUnfoldOutlined,
  IdcardOutlined,
  ContainerOutlined,
  DiffOutlined,
  PartitionOutlined,
  ReconciliationOutlined,
  DesktopOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { setToken } from '../../../store/modules/sistema/actions';
import { useApi } from '../../../hooks';
import { Icone } from '../../../styles/global';
import {
  Menu,
  SubMenu,
  Content,
  Item,
  Link,
  Avatar,
  Sider,
  Layout,
} from './styles';
import {
  setContaAvatar,
  setContaNome,
  setContaSobrenome,
} from '../../../store/modules/conta/actions';

const MenuAplicacao = ({ children, visivel }) => {
  const dispatch = useDispatch();
  const { buscar } = useApi();
  const [colapsar, setColapsar] = useState(true);
  const [mostrarSubmenu, setMostrarSubmenu] = useState(false);
  const { nome, avatarUrl, sobrenome, superUsuario } = useSelector(
    ({ contaReducer }) => contaReducer,
    shallowEqual,
  );

  useEffect(() => {
    const atualizarDadosUsuario = async () => {
      const { data } = await buscar('/sessoes');
      if (data) {
        const {
          avatarUrl: avatarAtualizado,
          nome: nomeAtualizado,
          sobrenome: sobrenomeAtualizado,
        } = data.usuario;
        dispatch(setContaAvatar(avatarAtualizado));
        dispatch(setContaNome(nomeAtualizado));
        dispatch(setContaSobrenome(sobrenomeAtualizado));
      }
    };

    if (!nome || !avatarUrl || !sobrenome) {
      atualizarDadosUsuario();
    }
  }, [buscar, dispatch, avatarUrl, nome, sobrenome]);

  const gerarIcone = () => {
    return (
      <Icone imagem={avatarUrl} backgroundColor="burlywood">
        {!avatarUrl && nome ? nome.substr(0, 2).toUpperCase() : ''}
      </Icone>
    );
  };

  const menuItems = [
    {
      linkTo: '#',
      label: 'Menu',
      icon: <MenuUnfoldOutlined />,
      onClick: () => {
        setColapsar(oldState => !oldState);
        setMostrarSubmenu(false);
      },
    },
    {
      linkTo: '/meus-acessos',
      label: 'Meus Acessos',
      icon: <DesktopOutlined />,
    },
    {
      linkTo: '/convidar',
      label: 'Convidar',
      icon: <UsergroupAddOutlined />,
    },
    {
      linkTo: '/meus-contratos',
      label: 'Meus Contratos',
      icon: <ContainerOutlined />,
    },
    { linkTo: '/contratar', label: 'Contratar', icon: <DiffOutlined /> },
    { linkTo: '/indicar', label: 'Indicar', icon: <PartitionOutlined /> },
    {
      linkTo: '/minha-conta',
      label: 'Meus dados',
      icon: <IdcardOutlined />,
    },
  ];

  return (
    <Layout menucolapsado={`${colapsar}`}>
      {visivel && (
        <Sider collapsed={colapsar} colapsar={`${colapsar}`}>
          <Menu
            mode="inline"
            theme="dark"
            selectable={false}
            colapsar={`${colapsar}`}
          >
            {menuItems.map(({ linkTo, label, icon, onClick }, index) => (
              <Item
                key={index}
                icon={icon}
                onClick={() => {
                  if (window.screen.width < 640 && !onClick) {
                    return setColapsar(true);
                  }
                  return onClick?.();
                }}
              >
                <Link to={linkTo}>{label}</Link>
              </Item>
            ))}

            {superUsuario && (
              <>
                <Item icon={<UserOutlined />}>
                  <Link to="/gerenciar-usuario">Gerenciar Usuários</Link>
                </Item>
                <Item icon={<ReconciliationOutlined />}>
                  <Link to="/papeis">Gerenciar Papeis</Link>
                </Item>
              </>
            )}

            <Avatar title="Avatar" colapsar={`${colapsar}`}>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    setMostrarSubmenu(valor => !valor);
                    setColapsar(false);
                  }}
                >
                  {gerarIcone()}
                </button>
              </div>
              <SubMenu
                mode="inline"
                selectable={false}
                colapsar={`${colapsar}`}
                esconder={`${!mostrarSubmenu}`}
              >
                <Item>
                  <Link
                    to="/"
                    onClick={() => {
                      dispatch(setToken(null));
                      localStorage.setItem('token', null);
                    }}
                  >
                    Logout
                  </Link>
                </Item>
              </SubMenu>
            </Avatar>
          </Menu>
        </Sider>
      )}
      <Content>{children}</Content>
    </Layout>
  );
};

MenuAplicacao.propTypes = {
  children: oneOfType([array, object]),
  visivel: bool,
};

MenuAplicacao.defaultProps = {
  visivel: true,
  children: [],
};

export default MenuAplicacao;
